@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic);
/* CUSTOM CSS */
@font-face {
  font-family: suisseInt;
  src: url('../fonts/SuisseIntl-Light-WebM.eot');
  src: url('../fonts/SuisseIntl-Light-WebM.eot?#iefix') format('embedded-opentype'), url('../fonts/SuisseIntl-Light-WebM.woff2') format('woff2'), url('../fonts/SuisseIntl-Light-WebM.woff') format('woff'), url('../fonts/SuisseIntl-Light-WebM.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: suisseInt;
  src: url('../fonts/SuisseIntl-Regular-WebM.eot');
  src: url('../fonts/SuisseIntl-Regular-WebM.eot?#iefix') format('embedded-opentype'), url('../fonts/SuisseIntl-Regular-WebM.woff2') format('woff2'), url('../fonts/SuisseIntl-Regular-WebM.woff') format('woff'), url('../fonts/SuisseIntl-Regular-WebM.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: suisseInt;
  src: url('../fonts/SuisseIntl-Medium-WebM.eot');
  src: url('../fonts/SuisseIntl-Medium-WebM.eot?#iefix') format('embedded-opentype'), url('../fonts/SuisseIntl-Medium-WebM.woff2') format('woff2'), url('../fonts/SuisseIntl-Medium-WebM.woff') format('woff'), url('../fonts/SuisseIntl-Medium-WebM.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: suisseInt;
  src: url('../fonts/SuisseIntl-SemiBold-WebM.eot');
  src: url('../fonts/SuisseIntl-SemiBold-WebM.eot?#iefix') format('embedded-opentype'), url('../fonts/SuisseIntl-SemiBold-WebM.woff2') format('woff2'), url('../fonts/SuisseIntl-SemiBold-WebM.woff') format('woff'), url('../fonts/SuisseIntl-SemiBold-WebM.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: suisseInt;
  src: url('../fonts/SuisseIntl-Bold-WebM.eot');
  src: url('../fonts/SuisseIntl-Bold-WebM.eot?#iefix') format('embedded-opentype'), url('../fonts/SuisseIntl-Bold-WebM.woff2') format('woff2'), url('../fonts/SuisseIntl-Bold-WebM.woff') format('woff'), url('../fonts/SuisseIntl-Bold-WebM.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* MIXINS */
.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}
.text-decoration-ink {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.clamp-line-1 {
  word-break: normal;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.flex-row.center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.relative {
  position: relative;
}
.auto-width {
  width: auto;
}
.no-padding {
  padding: 0;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.outside-buttons {
  position: absolute;
  top: 4px;
  right: 10px;
  display: inline-block;
  width: auto !important;
}
.prevent-click {
  pointer-events: none;
  cursor: default;
}
.grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.grayscale.blue {
  -webkit-filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
  filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
}
.grayscale.grey {
  -webkit-filter: grayscale(100%) brightness(110%) contrast(0.9);
  filter: grayscale(100%) brightness(110%) contrast(0.9);
}
.grayscale.black {
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
  filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
}
@-webkit-keyframes shine {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    left: -70%;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
  }
  @media only screen and (min-width: 768px) {
    0% {
      left: -40%;
    }
  }
  30% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
    filter: alpha(opacity=35);
    -webkit-opacity: 0.35;
    -moz-opacity: 0.35;
    opacity: 0.35;
    -webkit-transform: rotate(45deg) scale(1.5);
            transform: rotate(45deg) scale(1.5);
  }
  80% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
    left: 160%;
  }
  @media only screen and (min-width: 768px) {
    100% {
      left: 130%;
    }
  }
}
@keyframes shine {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    left: -70%;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
  }
  @media only screen and (min-width: 768px) {
    0% {
      left: -40%;
    }
  }
  30% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=35)";
    filter: alpha(opacity=35);
    -webkit-opacity: 0.35;
    -moz-opacity: 0.35;
    opacity: 0.35;
    -webkit-transform: rotate(45deg) scale(1.5);
            transform: rotate(45deg) scale(1.5);
  }
  80% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
    left: 160%;
  }
  @media only screen and (min-width: 768px) {
    100% {
      left: 130%;
    }
  }
}
.no-scroll {
  overflow: hidden;
}
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
.w20px {
  width: 20px;
}
.w40px {
  width: 40px;
}
.w50px {
  width: 50px;
}
.w60px {
  width: 60px;
}
.w70px {
  width: 70px;
}
.w100px {
  width: 100px;
}
.w110px {
  width: 110px;
}
.w150px {
  width: 150px;
}
.left-padding-8 {
  padding-left: 8px;
}
.left-padding-0 {
  padding-left: 0;
}
.right-padding-30 {
  padding-right: 30px;
}
.right-padding-110 {
  padding-right: 110px;
}
.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 !important;
  z-index: 1;
}
.text-right {
  text-align: right;
}
.zindex1 {
  position: relative;
  z-index: 1;
}
.zindex2 {
  position: relative;
  z-index: 2;
}
.zindex3 {
  position: relative;
  z-index: 3;
}
.zindex4 {
  position: relative;
  z-index: 4;
}
.zindex5 {
  position: relative;
  z-index: 5;
}
.zindex6 {
  position: relative;
  z-index: 6;
}
.zindex7 {
  position: relative;
  z-index: 7;
}
.zindex8 {
  position: relative;
  z-index: 8;
}
.action-buttons-last-col {
  width: 1px;
  white-space: nowrap;
}
/* STYLES */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="time"]::-webkit-clear-button,
input[type="date"]::-webkit-clear-button {
  display: none;
}
::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
::-webkit-search-results-button {
  -webkit-appearance: none;
}
.claimed {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 4px;
}
.veditor.loading {
  position: relative;
}
.veditor.loading:before {
  content: "";
  position: fixed;
  z-index: 30;
}
.veditor.loading:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: "\f1ce";
  position: fixed;
  top: 50%;
  left: calc(50% + 90px);
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  z-index: 30;
  pointer-events: none;
  font-size: 40px;
  line-height: 38px;
  font-family: "FontAwesome";
  text-align: center;
  color: #000000;
  border-radius: 50%;
}
.veditor.loading.success:before,
.veditor.loading.fail:before {
  -webkit-animation: spin-out-fade 1.5s 1 forwards linear;
          animation: spin-out-fade 1.5s 1 forwards linear;
}
.veditor.loading.success:after,
.veditor.loading.fail:after {
  color: white;
  background-color: #b5e450;
  border-radius: 50%;
  font-size: 30px;
  -webkit-animation: spin-out 1.5s 1 forwards linear;
          animation: spin-out 1.5s 1 forwards linear;
}
.veditor.loading.success:after {
  content: "\f00c";
  background-color: #b5e450;
}
.veditor.loading.fail:after {
  content: "\f00d";
  background-color: red;
}
.veditor.locked {
  position: relative;
}
.veditor.locked:before {
  content: "";
  position: fixed;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.veditor.locked:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: "\f023";
  position: fixed;
  top: 50%;
  left: calc(50% + 90px);
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  z-index: 30;
  pointer-events: none;
  font-size: 120px;
  line-height: 120px;
  font-family: "FontAwesome";
  text-align: center;
  color: #ddd;
  border-radius: 50%;
  -webkit-animation: none;
          animation: none;
}
.veditor.error {
  position: relative;
}
.veditor.error:before {
  content: "";
  position: fixed;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.veditor.error:after {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: "Something went wrong";
  position: fixed;
  top: 50%;
  left: calc(50% + 90px);
  height: 120px;
  width: 120px;
  margin: -60px 0 0 -60px;
  z-index: 30;
  pointer-events: none;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ddd;
  -webkit-animation: none;
          animation: none;
  background-image: url('../img/icon-error.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120px;
  padding-top: 120px;
}
.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: auto;
}
.sidebar {
  overflow: auto;
  max-height: calc(100vh - 50px);
  scrollbar-width: thin;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track-piece {
  background: #eee;
}
.sidebar::-webkit-scrollbar-thumb {
  background: #ccc;
}
.sidebar-menu {
  white-space: normal;
}
.sidebar-menu li.active li.header {
  color: #ffffff;
  background-color: #222;
  position: relative;
}
.sidebar-menu li.active li.header:before {
  content: "";
  width: 3px;
  position: absolute;
  left: -6px;
  top: 0;
  bottom: 0;
  background-color: #222;
  border-left: 3px solid #ff1e43;
}
.treeview-menu .treeview-menu {
  padding-left: 5px;
}
.picture-module,
.video-module {
  position: relative;
  padding-left: 130px;
}
.picture-module .form-group:first-child,
.video-module .form-group:first-child {
  position: absolute;
  left: 0;
  top: 0;
}
.picture-module textarea,
.video-module textarea {
  height: 110px;
}
.general-errors {
  -webkit-transform: translate(0px, -400px);
  transform: translate(0px, -400px);
  -webkit-transition: transform 300ms ease-out;
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  position: fixed;
  top: 55px;
  left: 30%;
  right: 30%;
  z-index: 1100;
  border-radius: 0;
  background-color: #ff1e43 !important;
}
.general-errors.show {
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.general-errors.expand ul {
  display: block;
}
.general-errors.expand .expander {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.general-errors .expander,
.general-errors .hider {
  border: 1px solid #ffffff;
  margin: 0 5px;
  cursor: pointer;
  padding: 0px 4px;
  background-color: transparent;
  height: 20px;
}
.general-errors .hider:before {
  font-size: 12px;
  line-height: 14px;
  display: block;
}
.general-errors h4 {
  margin-bottom: 0;
}
.general-errors ul {
  display: none;
  padding-top: 10px;
}
.general-errors .close {
  opacity: 1;
  color: #ffffff;
}
.general-errors .close:hover {
  opacity: 1;
}
.toolbar-article .btn {
  height: 38px;
}
.toolbar-article .btn-tool {
  background-color: #eee;
}
.toolbar-article .divider {
  float: right;
  width: 1px;
  height: 50px;
  background-color: #999999;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}
.pad {
  padding: 0;
}
.pool {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  margin-right: 50px;
}
.pool .current-pool {
  position: relative;
  display: inline-block;
}
.pool .current-pool label {
  font-size: 8px;
  font-size: 0.8rem;
  position: absolute;
  left: -40px;
  top: 8px;
  text-align: right;
  line-height: 8px;
}
.pool .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.description {
  padding-bottom: 10px;
}
.display-inline {
  display: inline-block;
}
.content-wrapper {
  padding-bottom: 50px;
}
.video-preview {
  height: 102px;
  width: 182px;
  background-color: #ccc;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.video-preview label {
  display: block;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
}
.upload-image {
  display: inline-block;
  background-color: #333333;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin-right: 4px;
}
.upload-image:last-child {
  margin-right: 0;
}
.upload-image label {
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 110px;
  height: 110px;
  font-weight: normal;
  margin: 0;
  cursor: pointer;
}
.upload-image:before {
  content: "\f067";
  font-family: "FontAwesome";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
.upload-image.order .btn-order {
  display: inline-block;
}
.upload-image.active:before {
  content: none;
}
.upload-image.active .btn-remove-item {
  display: block;
}
.upload-image.active .btn-preview-item {
  display: block;
}
.upload-image.active .btn-preview-item:hover .preview {
  display: block;
}
.upload-image.active label {
  line-height: 105px;
  text-shadow: 1px 1px #000000;
}
.upload-image span:not([class]) {
  position: absolute;
  bottom: 10px;
  left: 5px;
  right: 5px;
  text-align: center;
  pointer-events: none;
}
.upload-image .btn-order {
  font-size: 0;
  display: none;
  background: none;
  border: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
}
.upload-image .btn-order:hover {
  background: none;
}
.upload-image .btn-order .order-prev,
.upload-image .btn-order .order-next {
  width: 20px;
  height: 20px;
  position: relative;
  top: 0;
  left: 0;
}
.upload-image .btn-order .order-prev:before,
.upload-image .btn-order .order-next:before {
  line-height: 20px;
  width: 20px;
  height: 20px;
}
.upload-image .preview {
  display: none;
  position: absolute;
  left: 100%;
  bottom: 0;
  z-index: 4;
  width: 200px;
  border: 5px solid #000000;
}
.upload-image .preview.br {
  bottom: auto;
  top: 0;
}
.upload-image .preview.tr {
  bottom: 0;
  left: 100%;
}
.upload-image .preview.tl {
  left: auto;
  right: 100%;
  bottom: 0;
}
.upload-image .preview.bl {
  left: auto;
  right: 100%;
  bottom: auto;
  top: 0;
}
.upload-image .preview img {
  max-width: 100%;
}
.upload-image span.btn-remove-item {
  top: 0;
  right: 0;
  left: auto;
  bottom: auto;
}
.upload-image span.btn-preview-item {
  top: auto;
  right: auto;
  left: 0;
  bottom: 0;
}
.upload-image {
  z-index: 11;
}
.upload-image + .upload-image {
  z-index: 10;
}
.upload-image + .upload-image + .upload-image {
  z-index: 9;
}
.upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 8;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 7;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 6;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 5;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 4;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 3;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 2;
}
.upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image + .upload-image {
  z-index: 1;
}
.experience,
.prize,
.partner {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 150px;
  height: 150px;
  display: inline-block;
  background-color: #333333;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin-right: 4px;
}
.experience:last-child,
.prize:last-child,
.partner:last-child {
  margin-right: 0;
}
.experience:before,
.prize:before,
.partner:before {
  content: "\f067";
  font-family: "FontAwesome";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
.experience.add,
.prize.add,
.partner.add {
  max-width: 310px;
  margin-bottom: 5px;
  width: 100%;
}
.experience.active:before,
.prize.active:before,
.partner.active:before {
  content: "\f040";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0;
  width: auto;
  height: auto;
  text-align: center;
  line-height: 150px;
}
.experience.active .btn-remove-item,
.prize.active .btn-remove-item,
.partner.active .btn-remove-item {
  display: block;
}
.experience.active span:not(.btn-remove-item),
.prize.active span:not(.btn-remove-item),
.partner.active span:not(.btn-remove-item) {
  background-color: rgba(0, 0, 0, 0.5);
}
.experience span:not(.btn-remove-item),
.prize span:not(.btn-remove-item),
.partner span:not(.btn-remove-item) {
  position: absolute;
  bottom: 10px;
  left: 5px;
  right: 5px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
}
.partner:not(.add) {
  height: auto;
  width: 100%;
  background-color: transparent;
}
.partner:not(.add) span {
  font-size: 10px;
  line-height: 10px;
}
.partner figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-image: none;
  background-color: transparent;
  padding: 15px;
}
.partner figure img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
.merch-item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 150px;
  display: inline-block;
  background-color: #333333;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.merch-item:before {
  content: "\f067";
  font-family: "FontAwesome";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px 0 0 -10px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
.merch-item.add {
  max-width: 310px;
  margin-bottom: 5px;
}
.merch-item.active:before {
  content: "\f040";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0;
  width: auto;
  height: auto;
  text-align: center;
  line-height: 150px;
}
.merch-item.active .btn-remove-item {
  display: block;
}
.merch-item.active span:not(.btn-remove-item) {
  background-color: rgba(0, 0, 0, 0.5);
}
.merch-item span:not(.btn-remove-item) {
  position: absolute;
  bottom: 10px;
  left: 5px;
  right: 5px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 40px;
}
.partner-list {
  *zoom: 1;
  position: relative;
  margin: 0 -12px;
  text-align: center;
  clear: both;
}
.partner-list:before {
  content: " ";
  display: table;
}
.partner-list:after {
  content: " ";
  display: table;
  clear: both;
}
.partner-list .item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px !important;
  margin: 0 auto;
  display: inline-block;
}
.partner-list .item.gold {
  width: 100%;
}
.partner-list .item.gold .partner {
  height: 165px;
}
.partner-list .item.gold .partner.active:before {
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 1;
  line-height: 165px;
}
.partner-list .item.silver {
  width: 49%;
}
.partner-list .item.silver .partner {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.partner-list .item.silver .partner.active:before {
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 1;
  line-height: 110px;
}
.partner-list .item.bronze {
  width: 32.5%;
}
.partner-list .item.bronze .partner {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.partner-list .item.bronze .partner.active:before {
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 1;
  line-height: 80px;
}
.html-editor {
  width: 100%;
  height: 200px;
  font-size: 14px;
  line-height: 18px;
  border: 1px solid #dddddd;
  padding: 10px;
}
.html-preview {
  opacity: 0.5;
  height: 256px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #aaa;
  padding: 12px;
  background-color: #eee;
}
.html-preview .html-preview-content {
  pointer-events: none;
}
.html-preview .html-preview-content a {
  text-decoration: underline;
  color: #000000;
}
.info-box {
  border: 1px solid #d2d6de;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.info-box.contact-info {
  min-height: 90px;
}
.info-box .info-box-content {
  padding-bottom: 0;
  min-height: 90px;
}
.merch-list-wrapper,
.partners-list-wrapper {
  max-width: 330px;
  max-height: 480px;
  overflow: hidden;
  overflow-y: scroll;
}
.partner-list {
  margin: -5px;
  max-width: 320px;
}
.merch-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -5px;
  max-width: 320px;
}
.merch-list .merch-item {
  width: 100%;
  height: 150px;
}
.merch-list .item {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 50%;
  padding: 5px;
  display: inherit;
}
.merch-list .item.full-width {
  width: 100%;
}
.merch-list .item.full-width .merch-item {
  height: 150px;
  background-position: center top;
}
.merch-list .item.full-width .merch-item.active {
  height: 220px;
}
.buttons {
  padding: 10px;
  margin: 0 -10px -10px -10px;
  background-color: #d2d6de;
}
.btn:disabled {
  opacity: .25;
}
.btn:focus {
  outline: 0 !important;
}
.btn:focus:active {
  outline: 0 !important;
}
.btn-small {
  font-size: 12px;
  font-size: 1.2rem;
  height: 24px;
  line-height: 1;
}
.default-small {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1;
}
.btn-reset-tickets {
  position: absolute;
  right: 12px;
  top: 5px;
}
.btn-shadow {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 3px 3px 0 #000000;
  box-shadow: 3px 3px 0 #000000;
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 16px;
  color: #000000;
  background-color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border: 2px solid #000000;
  padding: 10px 35px;
  background-repeat: no-repeat;
  background-position: left 18px center;
  background-size: 16px;
}
.btn-shadow.inverted {
  -webkit-box-shadow: 3px 3px 0 #ffffff;
  box-shadow: 3px 3px 0 #ffffff;
  color: #ffffff;
  border-color: #ffffff;
  background-color: #000000;
}
.btn-shadow.inverted:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #000000;
  background-color: #ffffff;
}
.btn-shadow.icon {
  padding-left: 48px;
  padding-right: 22px;
}
.btn-shadow:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-shadow:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  background-color: #000000;
}
.btn-shadow:disabled,
.btn-shadow.disabled {
  -webkit-box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.45);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.45);
  pointer-events: none;
  cursor: default;
  border-color: rgba(0, 0, 0, 0.45);
  color: rgba(0, 0, 0, 0.55);
}
.btn-label {
  position: relative;
  padding-left: 48px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.btn-label img {
  pointer-events: none;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -15px;
}
.btn-youtube_video {
  position: relative;
  padding-left: 48px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.btn-youtube_video img {
  pointer-events: none;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -15px;
}

.btn-search-result {
  text-align: left;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  line-height: 12px;
}
.btn-search-result em {
  font-size: 10px;
  font-size: 1rem;
  display: block;
  font-style: normal;
  line-height: 14px;
}
.btn-search-result span {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}
.btn-remove-small {
  padding-left: 8px;
  padding-right: 28px;
  text-align: left;
  position: relative;
  margin-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.btn-remove-small:after {
  content: "\f00d";
  font-family: "FontAwesome";
  position: absolute;
  height: 10px;
  width: 16px;
  right: 4px;
  top: 50%;
  margin-top: -8px;
  line-height: 16px;
}
.btn-remove {
  position: relative;
  padding-left: 8px;
  padding-right: 32px;
  min-height: 44px;
  text-align: left;
  /*&:after {
		content: "\f00d";
		font-family: @font-icons;
		display: inline-block;
		position: absolute;
		height: 16px;
		width: 16px;
		right: 8px;
		top: 50%;
		margin-top: -8px;
		font-size: 16px;
		pointer-events: none;
		line-height: 16px;
		pointer-events: auto;
	}*/
}
.btn-remove.wrap {
  white-space: normal;
}
.btn-remove .remove {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  font-size: 20px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  pointer-events: auto;
}
.btn-remove .remove:before {
  line-height: 30px;
}
.btn-remove.match {
  background-color: #ff1e43;
  color: #ffffff;
}
.btn-remove:hover:not(.no-hover) {
  z-index: 3;
}
.btn-remove:hover:not(.no-hover) img {
  border-radius: 4px;
  position: absolute;
  width: 100px;
  height: 100px;
  margin-top: -25px;
}
.btn-remove span {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
}
.btn-order {
  position: relative;
  padding-left: 55px;
  padding-right: 36px;
}
.btn-order:hover,
.btn-order:active,
.btn-order:focus {
  background-color: #f4f4f4;
}
.btn-order:hover:focus,
.btn-order:active:focus,
.btn-order:focus:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #f4f4f4;
}
.btn-order .order-prev,
.btn-order .order-next,
.btn-order .close-me {
  font-size: 0px;
  display: inline-block;
  width: 24px;
  height: 34px;
  vertical-align: middle;
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  cursor: pointer;
}
.btn-order .order-prev:before,
.btn-order .order-next:before,
.btn-order .close-me:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  line-height: 32px;
  content: "\f060";
  font-family: "FontAwesome";
  background-color: #eee;
  width: 24px;
  height: 34px;
  display: block;
  text-align: center;
  border: 1px solid #ccc;
}
.btn-order .order-prev:hover:before,
.btn-order .order-next:hover:before,
.btn-order .close-me:hover:before {
  background-color: #ddd;
}
.btn-order .close-me {
  left: auto;
  right: 4px;
}
.btn-order .close-me:before {
  font-size: 14px;
  content: "\f00d";
}
.btn-order .order-next {
  left: 24px;
}
.btn-order .order-next:before {
  content: "\f061";
}
.btn-order:after {
  content: none;
}
.btn-artist {
  position: relative;
  padding-left: 48px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.btn-artist img {
  pointer-events: none;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 30px;
  width: 30px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  left: 8px;
  top: 50%;
  margin-top: -15px;
}
.btn-place {
  line-height: 12px;
}
.btn-place,
.btn-lineup {
  position: relative;
  padding-left: 48px;
  height: 48px;
}
.btn-place:before,
.btn-lineup:before {
  content: "\f041";
  font-family: "FontAwesome";
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 50%;
  height: 30px;
  width: 30px;
  margin-top: -12px;
  font-size: 18px;
}
.btn-place:after,
.btn-lineup:after {
  content: "";
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  background-color: #ccc;
}
.btn-duplicated-entity {
  font-size: 12px;
  height: 20px;
  padding: 2px 5px;
  position: absolute;
  right: 55px;
  top: 2px;
  z-index: 6;
  line-height: 1;
}
.btn-duplicated-entity:after {
  content: "\f08e";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  padding-left: 5px;
}
.btn-search-outside {
  font-size: 12px;
  height: 24px;
  padding: 2px 5px;
  right: 40px;
  line-height: 1;
}
.btn-search-outside:after {
  content: "\f08e";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  padding-left: 5px;
}
.btn-open-outside {
  font-size: 12px;
  height: 20px;
  padding: 2px;
  right: 28px;
  top: 2px;
  line-height: 1;
  position: absolute;
  z-index: 2;
}
.btn-open-outside:after {
  content: "\f08e";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  padding-left: 5px;
}
.btn-generate-name {
  font-size: 12px;
  height: 24px;
  padding: 2px 5px;
  right: 40px;
  line-height: 1;
  margin-left: 6px;
}
.btn-generate-name:after {
  content: "\f085";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  padding-left: 5px;
}
.btn-lineup:before {
  content: "\f130";
}
.btn-lineup span {
  line-height: 12px;
  font-size: 12px;
  text-transform: none;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 34px;
  white-space: normal;
}
.btn-remove-item {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ccc;
  width: 30px;
  height: 30px;
  z-index: 2;
  text-align: center;
}
.btn-remove-item:before {
  content: "\f00d";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  line-height: 30px;
}
.btn-preview-item {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ccc;
  width: 30px;
  height: 30px;
  z-index: 2;
  text-align: center;
}
.btn-preview-item:before {
  content: "\f00e";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  line-height: 30px;
}
.btn-closed-for-the-day {
  float: right;
  height: 20px;
  width: 20px;
  padding: 0;
}
.btn-closed-for-the-day.active {
  background-color: #000000;
}
.btn-closed-for-the-day.active:before {
  color: #ffffff;
}
.btn-closed-for-the-day.active:focus,
.btn-closed-for-the-day.active:hover {
  background-color: #000000;
}
.btn-closed-for-the-day.active:focus:active,
.btn-closed-for-the-day.active:hover:active {
  outline: 0;
  background-color: #000000;
}
.btn-clear-field {
  font-size: 12px;
  display: none;
}
.last-5-places .btn {
  margin: 8px 0 0 0;
}
.last-5-places .btn:first-child {
  margin-top: 0;
}
.input-group {
  width: 100%;
}
.multiple-dates > .input-group:first-child {
  z-index: 10;
}
.input-group + .input-group {
  z-index: 9;
}
.input-group + .input-group + .input-group {
  z-index: 8;
}
.input-group + .input-group + .input-group + .input-group {
  z-index: 7;
}
.input-group + .input-group + .input-group + .input-group + .input-group {
  z-index: 6;
}
.input-group + .input-group + .input-group + .input-group + .input-group + .input-group {
  z-index: 5;
}
.input-group + .input-group + .input-group + .input-group + .input-group + .input-group + .input-group {
  z-index: 4;
}
.input-group + .input-group + .input-group + .input-group + .input-group + .input-group + .input-group + .input-group {
  z-index: 3;
}
.clear {
  clear: both;
}
input:-moz-read-only {
  color: #bbb;
}
input:read-only {
  color: #bbb;
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-out {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  20% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
  80% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
  99% {
    -webkit-transform: rotate(360deg) scale(0);
            transform: rotate(360deg) scale(0);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0);
            transform: rotate(360deg) scale(0);
    display: none;
  }
}
@keyframes spin-out {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
  }
  20% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
  80% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
  99% {
    -webkit-transform: rotate(360deg) scale(0);
            transform: rotate(360deg) scale(0);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0);
            transform: rotate(360deg) scale(0);
    display: none;
  }
}
@-webkit-keyframes spin-out-fade {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes spin-out-fade {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
.loading {
  position: relative;
}
.loading * {
  pointer-events: none;
  cursor: default;
}
.loading:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 3;
  pointer-events: none;
}
.loading:after {
  content: "\f1ce";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
  z-index: 3;
  pointer-events: none;
  font-size: 20px;
  line-height: 20px;
  font-family: "FontAwesome";
  text-align: center;
  color: #000000;
}
.results-for-search {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 400px;
  max-height: 193px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 3;
}
.results-for-search.open {
  display: block;
}
.results-for-search .search-result-item {
  position: relative;
  overflow: visible;
}
.results-for-search .btn-search-result {
  font-weight: bold;
  white-space: initial;
  line-height: 1;
  display: block;
  width: 100%;
  padding-right: 70px;
  position: relative;
  overflow: visible;
}
.results-for-search .btn-search-result:last-child img:hover {
  bottom: 5px;
  top: auto;
}
.results-for-search .btn-search-result img {
  pointer-events: all;
}
.results-for-search .btn-search-result img:hover {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 5px;
  z-index: 2;
}
.results-for-search .btn-search-result em {
  font-size: 14px;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.results-for-search .btn-search-result span {
  font-size: 10px;
  font-size: 1rem;
  color: #999;
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.results-for-search .btn-search-result.btn-place span {
  padding-top: 0;
}
.results-for-search .btn-duplicated-entity {
  font-size: 12px;
  height: 20px;
  padding: 2px 5px;
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 1;
  line-height: 1;
  margin-top: -10px;
  background-color: #ddd;
}
.results-for-search .btn-duplicated-entity:after {
  content: "\f08e";
  color: #000000;
  font-size: 12;
  font-family: "FontAwesome";
  padding-left: 5px;
}
.lineup-group,
.tags-group {
  clear: both;
  height: 195px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 40px;
}
.lineup-group.autoheight,
.tags-group.autoheight {
  height: auto;
}
.lineup-group button,
.tags-group button {
  margin: 8px 5px 0 0;
}
.media-group {
  clear: both;
  height: 130px;
  overflow-y: hidden;
  overflow-x: auto;
  padding-bottom: 40px;
  white-space: nowrap;
}
.media-group .btn-preview-item:hover .preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.media-group .preview {
  max-height: 100px;
  width: auto;
}
.media-group .preview img {
  max-height: 90px;
  max-width: none;
  height: auto;
  width: auto;
}
.tags-group .btn-remove {
  height: 32px;
}
.row + .timetable {
  border-top: 2px solid #eee;
  padding-top: 10px;
  margin-top: 5px;
}
.timetable .form-control {
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.timetable .pagination > li > a,
.timetable .pagination > li > span {
  padding: 3px 6px;
}
.timetable .btn-small-square {
  width: 24px;
  height: 24px;
  padding: 4px;
  line-height: 1;
}
.timetable .tags-list {
  max-height: 60px;
  overflow: hidden;
  overflow-y: auto;
}
.timetable .btn-search-result.btn-artist {
  padding-left: 40px;
}
.timetable .btn-search-result.btn-artist img {
  left: 0;
}
.timetable .fa-circle.green:before {
  color: #b5e450;
}
.timetable .fa-circle.red:before {
  color: #ff1e43;
}
.timetable .fa-circle.grey:before {
  color: #ccc;
}
.timetable .tag {
  background-color: #333333;
  color: #ffffff;
  display: inline-block;
  margin-right: 5px;
  font-size: 8px;
  padding: 1px 5px;
  border-radius: 2px;
  text-transform: uppercase;
}
.timetable .filter-row {
  background-color: #ddd;
  color: #333333;
}
.timetable .filter-row td {
  padding-top: 4px;
  padding-bottom: 4px;
  border-color: #ddd;
}
.timetable .filter-row h4 {
  color: #999999;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: 24px;
}
.timetable tbody td {
  position: relative;
}
.timetable tbody td.editable {
  cursor: pointer;
}
.timetable tbody td.editable:hover:before {
  content: "\f040";
  position: absolute;
  top: 4px;
  right: 4px;
  width: 10px;
  height: 10px;
  z-index: 1;
  pointer-events: none;
  font-size: 10px;
  line-height: 10px;
  font-family: "FontAwesome";
  text-align: center;
  color: #000000;
}
.timetable .custom-select {
  font-weight: normal;
  font-size: 12px;
}
.timetable .custom-select.with-search .results {
  top: 52px;
}
.timetable .custom-select input[type="text"] {
  height: 28px;
  position: absolute;
  top: 34px;
  font-size: 12px;
}
.timetable .custom-select .selected-wrapper {
  height: 24px;
  line-height: 24px;
}
.timetable .custom-select .selected-wrapper:after {
  height: 24px;
  line-height: 24px;
}
.timetable .custom-select .selected-option {
  height: 24px;
  line-height: 24px;
  padding-top: 0;
}
.timetable .custom-select .results {
  top: 24px;
  height: 175px;
  z-index: 3;
}
.timetable .custom-select .results li {
  padding-top: 3px;
  padding-bottom: 3px;
}
.timetable .custom-select .reset-field {
  width: 24px;
  height: 24px;
  top: 2px;
}
.timetable .custom-select .reset-field:before {
  line-height: 18px;
  width: 20px;
  height: 20px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  background-color: #999;
  border-color: #999;
}
.custom-select {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 18px;
  width: 100%;
  position: relative;
  color: #000000;
  z-index: 2;
}
.custom-select.with-search.open input[type="text"] {
  display: block;
}
.custom-select.with-search.open .results {
  top: 68px;
  padding-top: 0;
}
.custom-select.optional .selected-wrapper {
  font-style: italic;
}
.custom-select.open {
  z-index: 12;
}
.custom-select.open .selected-wrapper:after {
  content: "\f0d8";
}
.custom-select.open .results {
  display: block;
}
.custom-select.disabled {
  pointer-events: none;
}
.custom-select.disabled .selected-wrapper {
  background-color: #eee;
}
.custom-select.small.with-search .results {
  top: 52px;
}
.custom-select.small input[type="text"] {
  font-size: 12px;
  height: 28px;
  top: 22px;
}
.custom-select.small .selected-wrapper {
  height: 24px;
  line-height: 24px;
}
.custom-select.small .selected-wrapper:after {
  height: 24px;
  line-height: 24px;
}
.custom-select.small .selected-option {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding-top: 0;
}
.custom-select.small .results {
  top: 24px;
  max-height: 175px;
}
.custom-select.small .results li {
  padding-top: 3px;
  padding-bottom: 3px;
}
.custom-select input[type="text"] {
  display: none;
  background-color: #ffffff;
  padding: 0 7px;
  line-height: 1;
  width: 100%;
  height: 34px;
  border: 3px solid #eee;
  position: absolute;
  top: 33px;
}
.custom-select input[type="text"] ::-webkit-input-placeholder {
  color: #999999;
}
.custom-select input[type="text"] ::-moz-placeholder {
  color: #999999;
}
.custom-select input[type="text"] :-ms-input-placeholder {
  color: #999999;
}
.custom-select input[type="text"] :-moz-placeholder {
  color: #999999;
}
.custom-select .selected-wrapper {
  padding-right: 20px;
  height: 34px;
  border: 1px solid #ccc;
  background-color: #ffffff;
}
.custom-select .selected-wrapper:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 34px;
  right: 2px;
  top: 0;
  content: "\f0d7";
  font-family: "FontAwesome";
  cursor: pointer;
  line-height: 34px;
  text-align: center;
}
.custom-select .selected-wrapper > label {
  font-size: 10px;
  text-transform: none;
}
.custom-select .selected-option {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 20px;
  left: 0px;
  height: 34px;
  line-height: 34px;
  color: #000000;
  cursor: pointer;
  outline: 0;
  padding-left: 8px;
}
.custom-select .selected-option.placeholder {
  color: #999999;
}
.custom-select .selected-option.placeholder + label {
  display: none;
}
.custom-select .results {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 10px;
  color: #333333;
  display: none;
  background-color: #f9f9f9;
  position: absolute;
  overflow: hidden;
  overflow-y: auto;
  max-height: 195px;
  top: 34px;
  left: 0;
  right: 0;
  z-index: 3;
  scroll-behavior: smooth;
  list-style: none;
  margin: 0;
  padding: 0;
}
.custom-select li {
  padding: 10px 8px;
  border-top: 1px solid #ddd;
}
.custom-select li:first-child {
  border-top: 0;
}
.custom-select li:hover,
.custom-select li.active {
  background-color: #ddd;
  cursor: pointer;
}
.custom-select .reset-field {
  font-size: 0px;
  display: inline-block;
  width: 34px;
  height: 34px;
  vertical-align: middle;
  content: "";
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
}
.custom-select .reset-field:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  content: "\f00d";
  font-family: "FontAwesome";
  background-color: #eee;
  width: 26px;
  height: 26px;
  display: block;
  text-align: center;
  border: 1px solid #ccc;
}
.float-list-item {
  float: left;
  margin: 0 10px 2px 0;
}
.float-list-item:last-child {
  margin-right: 0;
}
.float-list-item .checkbox {
  border: 1px solid #ccc;
  margin: 0;
}
.float-list-item .checkbox.no-border {
  border: 0;
}
.time-picker-wrapper {
  *zoom: 1;
  display: inline-block;
  float: right;
}
.time-picker-wrapper:before {
  content: " ";
  display: table;
}
.time-picker-wrapper:after {
  content: " ";
  display: table;
  clear: both;
}
.time-picker {
  width: 120px;
}
.old-link {
  font-size: 8px;
  font-size: 0.8rem;
  line-height: 1;
  display: block;
  color: #666;
}
.btn-copy-time {
  margin-top: 23px;
  float: left;
}
.form-control:focus {
  border-color: #000000;
}
.fixed-floater {
  -webkit-box-shadow: none;
          box-shadow: none;
  position: fixed;
  right: 14px;
  bottom: 14px;
  width: auto;
  padding: 5px;
  margin: 0;
  border: 1px solid #eee !important;
  z-index: 2;
  background: rgba(0, 0, 0, 0.15);
}
.fixed-floater button {
  margin: 5px;
}
.treeview-menu > li {
  padding-left: 8px;
}
.treeview-menu > li > a {
  font-size: 12px;
}
.treeview-menu > li > a > .fa {
  font-size: 8px;
  width: 12px;
}
.kucko-only {
  display: none;
}
.gmap-container {
  position: relative;
  height: 280px;
  width: 100%;
}
.gmap-container .error-msg {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
.gmap-container .gmap-search {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
}
.gmap-container .gmap-search-input {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-color: #ffffff;
  background-size: 22px;
  padding: 0 7px;
  line-height: 1;
  height: 34px;
  border: 1px solid #eee;
  width: 100%;
}
.gmap-container .gmap-search-input ::-webkit-input-placeholder {
  color: #999999;
}
.gmap-container .gmap-search-input ::-moz-placeholder {
  color: #999999;
}
.gmap-container .gmap-search-input :-ms-input-placeholder {
  color: #999999;
}
.gmap-container .gmap-search-input :-moz-placeholder {
  color: #999999;
}
.instructions {
  font-style: italic;
  padding-top: 10px;
}
label > small {
  font-size: 10px;
  color: #999999;
  font-weight: normal;
  font-style: italic;
}
.box-module {
  background-color: #eee;
}
.box-module .box-header h3 {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding-right: 4px;
}
.box-module .box-header h3 .fa {
  font-size: 16px;
}
.box-footer {
  padding-top: 0;
  padding-bottom: 0;
}
.label-link {
  color: inherit;
  text-decoration: underline;
}
/*
.box {
	z-index: 5;
}

.box + .box {
	z-index: 4;
}

.box + .box + .box {
	z-index: 3;
}

.box + .box + .box + .box {
	z-index: 2;
}

.box + .box + .box + .box + .box {
	z-index: 1;
}*/
.kucko-mode {
  font-size: 13px;
}
.kucko-mode .btn.btn-small {
  padding-top: 2px;
  padding-bottom: 2px;
}
.kucko-mode .btn-remove-item,
.kucko-mode .btn-preview-item {
  width: 20px;
  height: 20px;
}
.kucko-mode .btn-remove-item:before,
.kucko-mode .btn-preview-item:before {
  line-height: 20px;
}
.kucko-mode .media-group {
  height: 100px;
}
.kucko-mode .media-group .preview {
  max-height: 80px;
}
.kucko-mode .media-group .preview img {
  max-height: 70px;
}
.kucko-mode .general-errors {
  padding: 5px;
}
.kucko-mode .general-errors h4 {
  font-size: 16px;
}
.kucko-mode .instructions {
  display: none;
}
.kucko-mode .kucko-only {
  display: block;
}
.kucko-mode .screen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: calc(100vh - 0px);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.kucko-mode .screen:not(:first-child) > .row:first-child,
.kucko-mode .screen:not(:first-child) > .row:first-child > div:first-child,
.kucko-mode .screen:not(:first-child) > .row:first-child > div:first-child > .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.kucko-mode .screen:not(:first-child) > .row:first-child {
  padding-top: 70px;
}
.kucko-mode .main-sidebar {
  width: 180px;
}
.kucko-mode .content-wrapper {
  margin-left: 180px;
  padding-bottom: 0;
}
.kucko-mode .box {
  margin-bottom: 10px;
}
.kucko-mode .form-control {
  font-size: 13px;
  height: 24px;
  padding-left: 6px;
  padding-right: 6px;
}
.kucko-mode .btn-clear-field {
  display: table-cell;
}
.kucko-mode .reset-field {
  width: 24px;
  height: 24px;
  top: 2px;
}
.kucko-mode .reset-field:before {
  line-height: 18px;
  width: 20px;
  height: 20px;
}
.kucko-mode input[type="checkbox"] {
  margin-top: 2px;
}
.kucko-mode input,
.kucko-mode textarea {
  resize: none;
}
.kucko-mode textarea.form-control {
  height: auto;
  line-height: 16px;
}
.kucko-mode .checkbox label,
.kucko-mode .radio label {
  min-height: 18px;
}
.kucko-mode .form-group {
  margin-bottom: 5px;
}
.kucko-mode .form-horizontal .control-label {
  padding-top: 2px;
  text-align: left;
}
.kucko-mode .form-horizontal > .relative {
  z-index: 11;
}
.kucko-mode .box-header {
  padding-top: 4px;
  padding-bottom: 0;
  text-transform: uppercase;
}
.kucko-mode .box-header .box-title {
  font-size: 16px;
}
.kucko-mode .box-header h3 {
  padding-right: 4px;
}
.kucko-mode .custom-select {
  font-size: 12px;
}
.kucko-mode .custom-select.with-search .results {
  top: 52px;
}
.kucko-mode .custom-select input[type="text"] {
  height: 28px;
  top: 24px;
  position: absolute;
}
.kucko-mode .custom-select .selected-wrapper {
  height: 24px;
  line-height: 24px;
}
.kucko-mode .custom-select .selected-wrapper:after {
  height: 24px;
  line-height: 24px;
}
.kucko-mode .custom-select .selected-option {
  height: 24px;
  line-height: 24px;
  padding-top: 0;
}
.kucko-mode .custom-select .results {
  top: 24px;
  max-height: 175px;
}
.kucko-mode .custom-select .results li {
  padding-top: 3px;
  padding-bottom: 3px;
}
.kucko-mode .time-picker {
  width: 120px;
}
.kucko-mode .time-picker .input-group-addon {
  display: none;
}
.kucko-mode .time-picker .input-group-btn {
  display: table-cell;
}
.kucko-mode .time-picker .input-group .form-control {
  line-height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.kucko-mode .select2-container--default .select2-selection--single {
  height: 24px;
  padding-left: 6px;
  padding-right: 6px;
}
.kucko-mode .select2-container--default .select2-selection--single .select2-selection__rendered,
.kucko-mode .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 20px;
  line-height: 18px;
}
.kucko-mode .upload-image label {
  font-size: 11px;
  width: 80px;
  height: 80px;
}
.kucko-mode .upload-image span:not([class]) {
  bottom: 5px;
}
.kucko-mode .upload-image.active label {
  line-height: 75px;
}
.kucko-mode .input-group-addon {
  padding: 0 6px;
}
.kucko-mode .input-group-btn {
  vertical-align: top;
  padding: 0;
}
.kucko-mode .input-group-btn .btn {
  height: 24px;
  line-height: 1;
  padding: 0 6px;
}
.kucko-mode .timetable .table > tbody > tr > td,
.kucko-mode .timetable .table > tbody > tr > th,
.kucko-mode .timetable .table > tfoot > tr > td,
.kucko-mode .timetable .table > tfoot > tr > th,
.kucko-mode .timetable .table > thead > tr > td,
.kucko-mode .timetable .table > thead > tr > th {
  padding: 4px;
}
.kucko-mode .timetable th {
  font-size: 12px;
  font-size: 1.2rem;
}
.kucko-mode .timetable table.dataTable thead .sorting,
.kucko-mode .timetable table.dataTable thead .sorting_asc,
.kucko-mode .timetable table.dataTable thead .sorting_desc {
  padding-right: 15px;
}
.kucko-mode .timetable table.dataTable thead .sorting:after,
.kucko-mode .timetable table.dataTable thead .sorting_asc:after,
.kucko-mode .timetable table.dataTable thead .sorting_desc:after {
  font-size: 10px;
  font-size: 1rem;
}
.kucko-mode .lineup-group.autoheight {
  height: auto;
}
.kucko-mode .lineup-group.big {
  min-height: 660px;
}
.kucko-mode .lineup-group.small {
  height: 210px;
}
.kucko-mode .lineup-group.smallest {
  height: 80px;
  padding-bottom: 0px;
}
.kucko-mode .lineup-group .btn-stage {
  font-size: 12px;
  font-size: 1.2rem;
  min-height: 24px;
}
.kucko-mode .lineup-group .btn-stage span {
  height: 22px;
}
.kucko-mode .lineup-group .btn-stage span:before {
  height: 22px;
  line-height: 20px;
}
.kucko-mode .gmap-container {
  height: 205px;
}
.kucko-mode .gmap-search {
  top: 4px;
  left: 4px;
  right: 4px;
}
.kucko-mode .gmap-search .gmap-search-input {
  font-size: 13px;
  height: 24px;
}
.kucko-mode #lineup .box,
.kucko-mode #lineup .box-body {
  padding-bottom: 0;
}
.kucko-mode #lineup .box {
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
}
/*********************/
/* MATERIAL UI TABLE */
/*********************/
.MuiTableCell-head,
.MuiTableCell-root {
  font-size: 14px;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.shoutbox {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #eee;
  height: 400px;
}
.shoutbox h2 {
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.wrapper-scroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}
.list-shoutbox {
  position: relative;
}
.list-shoutbox.dark {
  background-color: #181818;
  color: #ffffff;
}
.list-shoutbox .item {
  padding: 0 0 10px 0;
  margin: 0;
  list-style: none;
}
.list-shoutbox .item.hide {
  display: none;
}
.list-shoutbox .item:last-child {
  padding-bottom: 0;
}
.card-shoutbox {
  display: block;
  position: relative;
  padding-right: 60px;
}
.card-shoutbox.author {
  margin-top: 20px;
}
.card-shoutbox.author figure {
  display: block;
}
.card-shoutbox.author .author-info {
  display: -webkit-box;
}
.card-shoutbox.festival {
  margin-top: 20px;
}
.card-shoutbox.festival figure {
  display: block;
}
.card-shoutbox.festival article .author-info {
  display: -webkit-box;
}
.card-shoutbox.festival article .message {
  color: #ffffff;
  background-color: #000000;
}
.card-shoutbox .btn-remove-msg {
  position: absolute;
  right: 10px;
  top: 0;
}
.card-shoutbox .report-action {
  position: ;
}
.card-shoutbox h3 {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 3px 0;
  font-weight: 600;
  word-break: break-word;
  padding-right: 10px;
}
.card-shoutbox time {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
}
.card-shoutbox figure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ddd;
  display: none;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}
.card-shoutbox figure img {
  max-width: 100%;
}
.card-shoutbox article {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  margin-left: 50px;
  text-align: left;
  clear: both;
}
.card-shoutbox .message {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  background-color: #ffffff;
  min-height: 40px;
  padding: 10px;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
}
.card-shoutbox .message a {
  color: #000000;
  text-decoration: underline;
}
.card-shoutbox .author-info {
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  letter-spacing: 1px;
  color: #999999;
  text-transform: uppercase;
  position: absolute;
  display: none;
  top: -20px;
  left: 0;
}
.card-shoutbox .author-info time {
  color: #999999;
}
.card-shoutbox .price {
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  display: block;
}
.custom-modal {
  position: fixed;
  z-index: 1100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: auto;
  display: none;
}
.custom-modal.open {
  display: block;
}
.media-container {
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/
  height: 0;
  overflow: hidden;
}
.media-container iframe,
.media-container object,
.media-container embed,
.media-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-container .media-controller {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.section-learn-banner {
  text-align: center;
  padding: 0;
  color: #000000;
}
@media only screen and (min-width: 768px) {
  .section-learn-banner {
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section-learn-banner h2 {
  font-size: 26px;
  line-height: 46px;
  font-weight: 500;
  padding-bottom: 30px;
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .section-learn-banner h2 {
    font-size: 32px;
    padding-bottom: 60px;
  }
}
.section-learn-banner h3 {
  font-size: 24px;
  line-height: 31px;
  font-weight: 500;
  padding-bottom: 30px;
  color: inherit;
}
@media only screen and (min-width: 768px) {
  .section-learn-banner h3 {
    padding-bottom: 0;
    text-align: left;
    padding-right: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-learn-banner h3 {
    font-size: 32px;
    line-height: 42px;
    padding-right: 80px;
  }
}
.section-learn-banner .learn-banner {
  background-color: #BEC6CB;
  padding: 24px;
}
@media only screen and (min-width: 768px) {
  .section-learn-banner .learn-banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 34px 60px;
  }
}
.section-learn-banner .btn-solid {
  font-size: 14px;
  padding: 12px 12px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  text-decoration: none !important;
}
@media only screen and (min-width: 768px) {
  .section-learn-banner .btn-solid {
    padding: 16px 24px;
    letter-spacing: 3px;
  }
}
.section-learn-banner .btn-solid.white {
  color: #000000;
  background-color: #ffffff;
}
.section-learn-banner .btn-solid.white:hover {
  background-color: #707580;
}
.section-learn-banner .btn-solid.black {
  color: #ffffff;
  background-color: #000000;
}
.section-learn-banner .btn-solid.black:hover {
  color: #000000;
  background-color: #ffffff;
}
.section-editors-pick-article {
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding: 0;
  font-weight: 400;
  line-height: 1.5em;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  text-rendering: optimizeLegibility;
}
.section-editors-pick-article .btn-copy-link {
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  background-color: #4e63ce;
  color: #ffffff;
  width: 100%;
  height: 52px;
  letter-spacing: 3px;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .btn-copy-link {
    font-size: 12px;
    border: 2px solid #4e63ce;
    font-weight: 500;
    height: 32px;
    letter-spacing: 1px;
    border-color: #4e63ce;
    color: #4e63ce;
    background-color: transparent;
  }
  .section-editors-pick-article .btn-copy-link:hover {
    background-color: #4e63ce;
    color: #ffffff;
  }
}
.section-editors-pick-article.v2 > .page {
  max-width: 1440px;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 > .page {
    max-width: 850px;
    padding-left: 0;
    padding-right: 0;
  }
}
.section-editors-pick-article.v2 .heading {
  height: auto;
  display: block;
  min-height: 0;
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article.v2 .heading {
    margin-left: -122px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading h1 {
    max-width: 636px;
    padding-right: 0;
    padding-left: 244px;
  }
}
.section-editors-pick-article.v2 .heading-content {
  position: relative;
  padding-bottom: 30px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}
.section-editors-pick-article.v2 .heading-content-sidebar {
  font-weight: 500;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content-sidebar {
    width: 220px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.section-editors-pick-article.v2 .heading-content-sidebar time {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  color: #707580;
  text-transform: uppercase;
  vertical-align: middle;
}
.section-editors-pick-article.v2 .heading-content-sidebar time:after {
  content: "\00a0\00a0|\00a0\00a0";
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content-sidebar time:after {
    content: none;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content-sidebar time {
    display: block;
    padding: 0 0 10px 0;
  }
}
.section-editors-pick-article.v2 .heading-content-sidebar .time-and-author {
  font-size: 0px;
  font-size: 0rem;
  padding: 10px 0 20px 0;
  vertical-align: middle;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content-sidebar .time-and-author {
    padding-top: 0;
  }
}
.section-editors-pick-article.v2 .heading-content-sidebar .author-info {
  position: relative;
  display: block;
  padding-left: 42px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 33px;
}
.section-editors-pick-article.v2 .heading-content-sidebar .author-info img {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  position: absolute;
  left: 0;
  top: 0;
}
.section-editors-pick-article.v2 .heading-content-sidebar .author-name {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 14px;
  color: #000000;
  display: inline-block;
  max-width: 150px;
}
@media only screen and (min-width: 480px) {
  .section-editors-pick-article.v2 .heading-content-sidebar .author-name {
    max-width: none;
  }
}
.section-editors-pick-article.v2 .heading-content-sidebar .tag-editor-pick {
  margin: 0 10px 10px 0;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content-sidebar .btn-filter-tag {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content {
    max-width: 636px;
    padding-left: 244px;
  }
}
.section-editors-pick-article.v2 .heading-content .btn-copy-link {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .heading-content .btn-copy-link {
    display: none;
  }
}
.section-editors-pick-article.v2 .content-wrapper {
  background-color: transparent;
  margin-left: 0;
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article.v2 .content-wrapper {
    margin-left: -122px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .content-wrapper img {
    padding-bottom: 60px;
  }
}
.section-editors-pick-article.v2 .content {
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .content {
    max-width: 636px;
    padding-left: 244px;
    margin: 0;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
  }
}
.section-editors-pick-article.v2 .content-sidebar {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .content-sidebar {
    float: left;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    display: block;
    width: 22%;
  }
}
.section-editors-pick-article.v2 .content-sidebar .btn-copy-link {
  margin-bottom: 20px;
}
.section-editors-pick-article.v2 .author {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 480px) {
  .section-editors-pick-article.v2 .author .page {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article.v2 .author .page {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .author .page {
    max-width: 850px;
    padding-left: 0;
    padding-right: 0;
  }
}
.section-editors-pick-article.v2 .author .content {
  position: relative;
  padding-bottom: 50px;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .author .content {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article.v2 .author .content {
    margin-left: -122px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .author h4 {
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .author h4 span {
    display: block;
  }
}
.section-editors-pick-article.v2 .author .author-details {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article.v2 .author .author-details article {
    max-width: 60%;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article.v2 .author .author-details article {
    max-width: none;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.section-editors-pick-article.v2 .author .author-details a {
  text-decoration: none;
}
.section-editors-pick-article.v2 .tags {
  font-size: 0px;
  font-size: 0rem;
}
.section-editors-pick-article.v2 .tags .btn-filter-tag {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 .tags .btn-filter-tag {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
.section-editors-pick-article.v2 blockquote {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: 500;
  padding: 30px 0 30px 8.33%;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article.v2 blockquote {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section-editors-pick-article.v2 figure + blockquote {
  padding-top: 0;
}
.section-editors-pick-article.v2 blockquote + figure {
  margin-top: 20px;
}
.section-editors-pick-article.v2 h1 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 20px;
  padding-top: 30px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article.v2 h1 {
    font-size: 36px;
    font-size: 3.6rem;
    line-height: 44px;
    padding-top: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 h1 {
    font-size: 42px;
    font-size: 4.2rem;
    line-height: 49px;
    padding-bottom: 60px;
    padding-top: 60px;
    padding-right: 8.33%;
    padding-left: 25%;
  }
}
.section-editors-pick-article.v2 figure {
  border: 0;
  display: block;
  margin: 30px 0;
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article.v2 figure {
    margin: 50px 0;
  }
}
.section-editors-pick-article.v2 figure .media-container {
  float: none;
  width: 100%;
}
.section-editors-pick-article.v2 figure img {
  width: auto;
  float: none;
}
.section-editors-pick-article.v2 figure figcaption {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 500;
  float: none;
  display: block;
  width: 100%;
  padding: 10px 0;
}
.section-editors-pick-article .tag-editor-pick {
  font-size: 12px;
  color: #ffffff;
  background-color: #ff1e43;
  text-transform: uppercase;
  padding: 6px 12px;
  font-weight: 500;
}
.section-editors-pick-article img {
  max-width: 100%;
}
.section-editors-pick-article .btn-filter-tag {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  border: 2px solid #000000;
  text-transform: uppercase;
  padding: 4px 12px;
  white-space: nowrap;
  margin: 0 10px 10px 0;
  border-color: #efefef;
  color: #000000;
  text-decoration: none;
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article .btn-filter-tag {
    float: left;
    clear: left;
    margin-bottom: 14px;
  }
}
.section-editors-pick-article .btn-filter-tag.white {
  color: #ffffff;
  border-color: #ffffff;
}
.section-editors-pick-article .btn-filter-tag.white.active {
  background-color: #ffffff;
  color: #000000;
}
.section-editors-pick-article .btn-filter-tag.white:hover {
  background-color: #ffffff;
  color: #000000;
}
.section-editors-pick-article .btn-filter-tag.active {
  background-color: #000000;
  color: #ffffff;
}
.section-editors-pick-article .btn-filter-tag:disabled,
.section-editors-pick-article .btn-filter-tag .disabled {
  opacity: 0.2;
}
.section-editors-pick-article .btn-filter-tag:disabled:hover,
.section-editors-pick-article .btn-filter-tag .disabled:hover {
  border-color: #ffffff;
}
.section-editors-pick-article .btn-filter-tag:hover {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.section-editors-pick-article .btn-close-modal {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 20;
}
.section-editors-pick-article .page {
  position: relative;
  clear: both;
  margin: 0 auto;
}
@media only screen and (min-width: 480px) {
  .section-editors-pick-article .page {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .page {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .page {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article .page {
    padding: 0 90px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .side-content {
    position: fixed;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
  }
}
.section-editors-pick-article .side-content .wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: transparent;
  height: 300px;
  margin-left: 0;
  overflow: visible;
  height: auto;
  position: fixed;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .side-content .wrapper {
    height: 500px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .side-content .wrapper {
    background-position: left 70px center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: auto;
  }
}
.section-editors-pick-article .side-content header {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 35px;
  background-color: #ff1e43;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .side-content header {
    display: block;
    width: 70px;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article .side-content header {
    width: 90px;
  }
}
.section-editors-pick-article .side-content h2 {
  color: #ffffff;
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -ms-transform-origin: top left;
  -o-transform-origin: top left;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-1deg);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  white-space: nowrap;
  position: absolute;
  bottom: 20px;
  left: 7px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .side-content h2 {
    font-size: 42px;
    left: 25px;
    bottom: 50px;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article .side-content h2 {
    left: 35px;
  }
}
.section-editors-pick-article .content {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding-left: 35px;
  padding-top: 0;
  padding-bottom: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .content {
    font-size: 16px;
    line-height: 24px;
    padding-left: 70px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .content {
    padding-left: 50%;
  }
}
.section-editors-pick-article .content .wrapper {
  background-color: transparent;
  position: static;
  overflow: visible;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .content .wrapper {
    padding-left: 16.66%;
  }
}
@media only screen and (min-width: 1399px) {
  .section-editors-pick-article .content .wrapper {
    padding-left: 90px;
  }
}
.section-editors-pick-article .content a {
  text-decoration: underline;
  color: #000000;
}
.section-editors-pick-article .content strong {
  font-weight: 500;
}
.section-editors-pick-article blockquote {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 50px 0;
  margin: 0;
  border: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article blockquote {
    font-size: 42px;
    line-height: 50px;
    padding: 70px 0;
  }
}
.section-editors-pick-article figure + blockquote {
  margin-top: 0;
}
.section-editors-pick-article blockquote + figure {
  margin-top: 30px;
}
.section-editors-pick-article figure {
  margin: 50px 0;
  border-top: 2px solid #000000;
  border-bottom: 2px solid #000000;
  padding: 28px 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article figure {
    *zoom: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 90px 0;
    padding: 28px 0;
  }
  .section-editors-pick-article figure:before {
    content: " ";
    display: table;
  }
  .section-editors-pick-article figure:after {
    content: " ";
    display: table;
    clear: both;
  }
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article figure img,
  .section-editors-pick-article figure .media-container {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    float: right;
    width: 75%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
}
.section-editors-pick-article figure figcaption {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-top: 10px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article figure figcaption {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    float: left;
    width: 25%;
    padding-right: 30px;
    padding-top: 0;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
.section-editors-pick-article .article-info {
  opacity: 1;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 30px;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .article-info {
    padding-top: 90px;
  }
}
.section-editors-pick-article .article-info time {
  display: block;
  font-weight: normal;
  color: #707580;
  padding-right: 40px;
}
.section-editors-pick-article .article-info .tags {
  display: block;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .article-info .tags {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .article-info .tags {
    display: inline-block;
  }
}
.section-editors-pick-article .article-info .text-tag {
  font-weight: 500;
  margin-right: 20px;
}
.section-editors-pick-article .article-info .text-tag:last-child {
  margin-right: 0;
}
.section-editors-pick-article .article-info a.text-tag {
  text-decoration: none;
  border-bottom: 2px solid black;
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .heading {
    min-height: calc(100vh - 90px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.section-editors-pick-article h1 {
  font-size: 40px;
  line-height: 46px;
  font-weight: 500;
  padding-bottom: 40px;
  padding-top: 30px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article h1 {
    font-size: 60px;
    line-height: 66px;
    padding-bottom: 60px;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 1199px) {
  .section-editors-pick-article h1 {
    font-size: 80px;
    line-height: 86px;
    padding-bottom: 60px;
  }
}
.section-editors-pick-article h2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article h2 {
    font-size: 22px;
    line-height: 32px;
  }
}
.section-editors-pick-article h3 {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article h3 {
    font-size: 18px;
    line-height: 24px;
  }
}
.section-editors-pick-article h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 0;
  margin: 0;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article h4 {
    font-size: 16px;
    line-height: 22px;
  }
}
.section-editors-pick-article h2 + p {
  padding-top: 10px;
}
.section-editors-pick-article p + h2 {
  padding-top: 30px;
}
.section-editors-pick-article ul,
.section-editors-pick-article ol {
  margin: 0;
  padding: 15px 0 15px 20px;
}
.section-editors-pick-article ul li,
.section-editors-pick-article ol li {
  padding: 4px 0;
}
.section-editors-pick-article ul li {
  list-style: disc;
}
.section-editors-pick-article sup,
.section-editors-pick-article sub {
  font-size: 10px;
}
.section-editors-pick-article sub {
  vertical-align: 0em;
}
.section-editors-pick-article sup {
  vertical-align: 1em;
  top: 0;
}
.section-editors-pick-article .lead {
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .lead {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 40px;
  }
}
.section-editors-pick-article .author {
  background-color: #f6f6f6;
  padding: 40px 20px;
  margin: 30px -20px 0 -20px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .author {
    margin: 60px -40px 0 -50px;
    padding-left: 50px;
    padding-right: 40px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .section-editors-pick-article .author {
    margin: 90px -90px 0 -105px;
    padding-left: 100px;
    padding-right: 90px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.section-editors-pick-article .author h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-bottom: 30px;
}
.section-editors-pick-article .author h5 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0;
}
.section-editors-pick-article .author .author-details {
  font-size: 12px;
  line-height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .author .author-details {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
}
.section-editors-pick-article .author article {
  padding-left: 30px;
}
.section-editors-pick-article .author p {
  padding-bottom: 0;
  margin-bottom: 0;
}
.section-editors-pick-article .author img {
  width: 80px;
  height: 80px;
}
@media only screen and (min-width: 768px) {
  .section-editors-pick-article .author img {
    width: 105px;
    height: 105px;
  }
}
.scraped-info {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #CCC;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 12px;
  line-height: 1em;
  padding: 10px;
  display: block;
  word-wrap: break-word;
  overflow: auto;
  max-height: 400px;
  width: 100%;
}
.orig-event-name {
  padding: 0;
  color: #ccc;
  margin: 0 0 5px 0;
  height: 25px;
  font-style: italic;
  overflow-y: auto;
  line-height: 12px;
}
.list-media-wrapper {
  overflow: hidden;
  height: 105px;
  position: relative;
}
.photo-single-row {
  padding-left: 90px;
  position: relative;
}
.photo-single-row .absolute-top-left {
  position: absolute;
  left: 0;
  top: 0;
}
.list-media {
  white-space: nowrap;
  vertical-align: top;
  overflow-x: auto;
}
.list-media .preview {
  height: 80px;
  width: auto;
}
.list-media .preview img {
  max-height: 100%;
  max-width: unset;
}
.list-media > p {
  padding: 0 5px;
  color: #bbb;
}
.list-media .list-item {
  height: 80px;
  width: 80px;
  display: inline-block;
  margin: 0 3px 0 0;
}
.list-media .list-item:last-child {
  margin-right: 0;
}
.list-matched-events {
  overflow-x: auto;
  height: 205px;
  white-space: nowrap;
  margin: 0 -5px;
}
.list-matched-events > p {
  padding: 0 5px;
  color: #bbb;
}
.list-matched-events .list-item {
  height: 180px;
  width: 210px;
  display: inline-block;
  padding: 0 5px;
}
.list-matched-events .matched-event {
  font-size: 11px;
  font-size: 1.1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 180px;
  background: #eee;
  padding: 10px;
  position: relative;
  white-space: normal;
}
.list-matched-events .matched-event:before {
  content: "";
  position: absolute;
  height: 20px;
  background-color: #dadada;
  left: 0;
  right: 0;
  top: 0;
}
.list-matched-events .matched-event article {
  overflow: auto;
  position: absolute;
  top: 20px;
  left: 5px;
  right: 5px;
  bottom: 36px;
}
.list-matched-events .matched-event time {
  color: #aaa;
}
.list-matched-events .matched-event .btn-delete-event {
  font-size: 11px;
  font-size: 1.1rem;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 1;
  height: 16px;
  line-height: 15px;
  padding: 0 5px;
  border-color: #aaa;
  background-color: #eee;
}
.list-matched-events .matched-event .btn-delete-event:after {
  font-size: 16px;
  font-size: 1.6rem;
  content: "\00a0×";
  font-weight: bold;
  display: inline-block;
  vertical-align: bottom;
}
.list-matched-events .matched-event .title {
  font-weight: bold;
  display: block;
}
.list-matched-events .matched-event .lineup-list {
  padding-top: 5px;
  display: block;
}
.list-matched-events .matched-event .lineup-list span {
  font-size: 11px;
  font-size: 1.1rem;
  display: inline-block;
  background-color: #999;
  color: #ffffff;
  padding: 0 4px;
  border-radius: 2px;
  margin-right: 4px;
  margin-bottom: 4px;
}
.list-matched-events .matched-event .btn-match-event {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.list-matched-events .matched-event .accuracy {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding: 2px 4px;
  font-size: 11px;
  z-index: 1;
  width: 40px;
}
.you-shall-not-pass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #000000;
  color: #ffffff;
}
.you-shall-not-pass h1 {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.has-error input[type="text"],
.has-error input[type="email"],
.has-error input[type="search"],
.has-error input[type="time"],
.has-error input[type="date"],
.has-error input[type="number"],
.has-error input[type="url"] {
  background-color: rgba(255, 0, 0, 0.2);
}
.checkbox {
  position: relative;
  padding: 0;
}
.checkbox.normal input[type="checkbox"] {
  opacity: 1;
  width: auto;
  height: auto;
  position: relative;
  top: 2px;
}
.checkbox.pre-selected label {
  background-color: transparent;
}
.checkbox.pre-selected label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #000000;
}
.checkbox input[type="checkbox"] {
  opacity: 0;
  -webkit-appearance: checkbox;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.checkbox input[type="checkbox"]:checked + label {
  background-color: rgba(255, 0, 0, 0.5);
}
.checkbox input[type="checkbox"]:checked + label:before {
  content: none;
}
.checkbox label {
  padding-left: 0;
  padding: 2px 4px;
}
.radio input[type="radio"] {
  -webkit-appearance: radio;
}
.colorpicker .input-group-addon i {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  vertical-align: text-top;
  width: 16px;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 20px;
  display: none;
}
.modal.grey {
  background-color: #efefef;
}
.modal.edit-form:before,
.modal.add-form:before,
.modal.search-form:before,
.modal.onboarding-form:before,
.modal.modal-close:before {
  content: none;
}
.modal.edit-form .modal-content,
.modal.add-form .modal-content,
.modal.search-form .modal-content,
.modal.onboarding-form .modal-content,
.modal.modal-close .modal-content {
  max-width: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media only screen and (min-width: 768px) {
  .modal.edit-form .modal-content,
  .modal.add-form .modal-content,
  .modal.search-form .modal-content,
  .modal.onboarding-form .modal-content,
  .modal.modal-close .modal-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .modal.edit-form .modal-content,
  .modal.add-form .modal-content,
  .modal.search-form .modal-content,
  .modal.onboarding-form .modal-content,
  .modal.modal-close .modal-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media only screen and (min-width: 1199px) {
  .modal.edit-form .modal-content,
  .modal.add-form .modal-content,
  .modal.search-form .modal-content,
  .modal.onboarding-form .modal-content,
  .modal.modal-close .modal-content {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
.modal.registration-form {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}
.modal.registration-form.open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.modal.registration-form .modal-content {
  height: 100%;
  width: 100%;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
@media only screen and (min-width: 1199px) {
  .modal.registration-form .modal-content {
    max-width: 1024px;
    max-height: 760px;
  }
}
.modal.registration-form:before {
  opacity: 0.5;
  background: #000000;
}
.modal.open {
  display: block;
}
.modal.open:before {
  opacity: 0.5;
}
.modal:before {
  opacity: 0;
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(../img/noise-animated-dark.gif);
  background-repeat: repeat;
  background-size: 400px;
  background-position: center center;
  z-index: -1;
}
.modal .page {
  padding: 20px;
  margin: 0;
  max-width: none;
}
@media only screen and (min-width: 480px) {
  .modal .page {
    padding: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .modal .page {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .modal .page {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (min-width: 1199px) {
  .modal .page {
    padding-left: 90px;
    padding-right: 90px;
  }
}
.modal .modal-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  background-color: #ffffff;
  z-index: 1;
  margin: 0 auto;
  position: absolute;
  max-width: 1258px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  right: 20px;
  top: 20px;
  bottom: 20px;
  left: 20px;
}
@media only screen and (min-width: 768px) {
  .modal .modal-content {
    right: 40px;
    top: 40px;
    bottom: 40px;
    left: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .modal .modal-content {
    right: 40px;
    top: 40px;
    bottom: 40px;
    left: 40px;
  }
}
@media only screen and (min-width: 1199px) {
  .modal .modal-content {
    right: 90px;
    top: 90px;
    bottom: 90px;
    left: 90px;
  }
}
.modal .modal-main {
  position: relative;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .modal .modal-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.modal .modal-scroller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.modal .modal-scroller > .page {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.modal .btn-close {
  font-size: 0px;
  font-size: 0rem;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  background-size: 16px;
  z-index: 1;
}
@media only screen and (min-width: 768px) {
  .modal .btn-close {
    width: 22px;
    height: 22px;
    background-size: 22px;
  }
}
@media only screen and (min-width: 1439px) {
  .modal .btn-close {
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    background-size: 30px;
  }
}
.registration-form {
  display: block;
}
.registration-form input[type="text"],
.registration-form input[type="number"],
.registration-form input[type="email"],
.registration-form input[type="password"],
.registration-form input[type="tel"] {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 1.4rem;
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  -o-user-select: auto;
  line-height: 18px;
  letter-spacing: 0.5px;
  width: 100%;
  position: relative;
  color: #000000;
  border: 0;
  border-bottom: 2px solid #000000;
  padding-right: 20px;
  height: 48px;
  padding-top: 10px;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: baseline;
  outline: none;
  z-index: 2;
}
.registration-form input[type="text"]:-moz-read-only:not(.mbsc-comp),
.registration-form input[type="number"]:-moz-read-only:not(.mbsc-comp),
.registration-form input[type="email"]:-moz-read-only:not(.mbsc-comp),
.registration-form input[type="password"]:-moz-read-only:not(.mbsc-comp),
.registration-form input[type="tel"]:-moz-read-only:not(.mbsc-comp) {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.registration-form input[type="text"]:read-only:not(.mbsc-comp),
.registration-form input[type="number"]:read-only:not(.mbsc-comp),
.registration-form input[type="email"]:read-only:not(.mbsc-comp),
.registration-form input[type="password"]:read-only:not(.mbsc-comp),
.registration-form input[type="tel"]:read-only:not(.mbsc-comp) {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.registration-form input[type="text"].error::-webkit-input-placeholder,
.registration-form input[type="number"].error::-webkit-input-placeholder,
.registration-form input[type="email"].error::-webkit-input-placeholder,
.registration-form input[type="password"].error::-webkit-input-placeholder,
.registration-form input[type="tel"].error::-webkit-input-placeholder {
  color: #ff1e43;
}
.registration-form input[type="text"].error:-ms-input-placeholder,
.registration-form input[type="number"].error:-ms-input-placeholder,
.registration-form input[type="email"].error:-ms-input-placeholder,
.registration-form input[type="password"].error:-ms-input-placeholder,
.registration-form input[type="tel"].error:-ms-input-placeholder {
  color: #ff1e43;
}
.registration-form input[type="text"].error::-ms-input-placeholder,
.registration-form input[type="number"].error::-ms-input-placeholder,
.registration-form input[type="email"].error::-ms-input-placeholder,
.registration-form input[type="password"].error::-ms-input-placeholder,
.registration-form input[type="tel"].error::-ms-input-placeholder {
  color: #ff1e43;
}
.registration-form input[type="text"].error::placeholder,
.registration-form input[type="number"].error::placeholder,
.registration-form input[type="email"].error::placeholder,
.registration-form input[type="password"].error::placeholder,
.registration-form input[type="tel"].error::placeholder {
  color: #ff1e43;
}
.registration-form input[type="text"].optional,
.registration-form input[type="number"].optional,
.registration-form input[type="email"].optional,
.registration-form input[type="password"].optional,
.registration-form input[type="tel"].optional {
  font-style: italic;
}
.registration-form input[type="text"]:focus,
.registration-form input[type="number"]:focus,
.registration-form input[type="email"]:focus,
.registration-form input[type="password"]:focus,
.registration-form input[type="tel"]:focus {
  outline: none;
}
.registration-form input[type="text"]::-webkit-input-placeholder,
.registration-form input[type="number"]::-webkit-input-placeholder,
.registration-form input[type="email"]::-webkit-input-placeholder,
.registration-form input[type="password"]::-webkit-input-placeholder,
.registration-form input[type="tel"]::-webkit-input-placeholder {
  color: #989898;
}
.registration-form input[type="text"]:-ms-input-placeholder,
.registration-form input[type="number"]:-ms-input-placeholder,
.registration-form input[type="email"]:-ms-input-placeholder,
.registration-form input[type="password"]:-ms-input-placeholder,
.registration-form input[type="tel"]:-ms-input-placeholder {
  color: #989898;
}
.registration-form input[type="text"]::-ms-input-placeholder,
.registration-form input[type="number"]::-ms-input-placeholder,
.registration-form input[type="email"]::-ms-input-placeholder,
.registration-form input[type="password"]::-ms-input-placeholder,
.registration-form input[type="tel"]::-ms-input-placeholder {
  color: #989898;
}
.registration-form input[type="text"]::placeholder,
.registration-form input[type="number"]::placeholder,
.registration-form input[type="email"]::placeholder,
.registration-form input[type="password"]::placeholder,
.registration-form input[type="tel"]::placeholder {
  color: #989898;
}
.registration-form .reason {
  font-weight: 500;
}
.registration-form .loading:before {
  z-index: 15;
}
.registration-form form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.registration-form .btn-close {
  width: 30px;
  height: 30px;
  background-size: 30px;
}
.registration-form .logo {
  font-size: 0px;
  font-size: 0rem;
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}
@media only screen and (min-width: 768px) {
  .registration-form .logo {
    width: 70px;
    height: 70px;
  }
}
@media only screen and (min-width: 1199px) {
  .registration-form .logo {
    width: 90px;
    height: 90px;
  }
}
.registration-form .logo img {
  width: 100%;
}
.registration-form .modal-content {
  background-color: #efefef;
  overflow-y: auto;
}
.registration-form .modal-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 40px 0 0 50%;
  border: 0;
}
.registration-form .modal-scroller {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-scroller {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-y: auto;
  }
}
.registration-form .modal-aside {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #000000;
  color: #000000;
  z-index: 1;
  background-image: url(../img/noise-animated-bright.gif);
  background-repeat: repeat;
  background-size: 400px;
  background-position: center center;
  padding: 20px;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-aside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    display: block;
    padding: 100px 20px 0 20px;
  }
}
@media only screen and (min-width: 1199px) {
  .registration-form .modal-aside {
    width: 50%;
    bottom: 0;
    right: auto;
  }
}
.registration-form .modal-aside .aside-page {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside .aside-page {
    padding: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-aside .aside-page {
    text-align: left;
    padding-top: 30px;
    max-width: 400px;
  }
}
.registration-form .modal-aside ul {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside ul {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 30px;
    padding-bottom: 40px;
  }
}
.registration-form .modal-aside ul li {
  padding: 5px 0;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside ul li {
    padding: 8px 0;
  }
}
.registration-form .modal-aside h2 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 50px;
  letter-spacing: 1px;
  font-weight: 500;
  padding-bottom: 24px;
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-aside h2 {
    font-size: 80px;
    font-size: 8rem;
    line-height: 90px;
  }
}
.registration-form .modal-aside h3 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 38px;
  letter-spacing: 1px;
  font-weight: 500;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside h3 {
    font-size: 42px;
    font-size: 4.2rem;
    line-height: 49px;
    padding-bottom: 24px;
  }
}
.registration-form .modal-aside p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 22px;
  padding-top: 20px;
  font-weight: 500;
}
@media only screen and (min-width: 768px) {
  .registration-form .modal-aside p {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 30px;
  }
}
.registration-form .modal-aside h3 + p {
  max-width: 200px;
}
.registration-form .modal-main {
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-main {
    margin-top: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .modal-aside + .modal-main {
    margin-left: 50%;
  }
}
.registration-form .chapter {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: none;
  clear: both;
  background-color: #EFEFEF;
}
.registration-form .chapter.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.registration-form .chapter.hidden {
  display: none;
}
.registration-form .chapter.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.registration-form .chapter.fullscreen {
  background-color: #EFEFEF;
}
.registration-form .chapter.fullscreen > .page {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.registration-form .chapter.fullscreen .form-article {
  width: auto;
  text-align: left;
  margin: 0;
  padding-top: 50px;
}
@media only screen and (min-width: 1024px) {
  .registration-form .chapter.fullscreen .form-article {
    max-width: 70%;
  }
}
.registration-form .chapter.fullscreen h2 {
  font-size: 40px;
  font-size: 4rem;
  line-height: 50px;
  letter-spacing: 1px;
  font-weight: 500;
  padding-bottom: 24px;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .registration-form .chapter.fullscreen h2 {
    font-size: 80px;
    font-size: 8rem;
    line-height: 90px;
  }
}
.registration-form .chapter.fullscreen p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 20px;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .registration-form .chapter.fullscreen p {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 30px;
    padding-bottom: 40px;
  }
}
.registration-form .chapter.login .btn-shadow {
  margin-bottom: 20px;
}
.registration-form .chapter.login .page:not(.loading):before {
  content: "This feature is not available on mobile version yet. If you want to follow an iD, please visit our website from your computer.";
  text-align: center;
  display: block;
}
@media only screen and (min-width: 768px) {
  .registration-form .chapter.login .page:not(.loading):before {
    content: none;
    display: none;
  }
}
.registration-form .chapter.login .form-article {
  display: none;
}
@media only screen and (min-width: 768px) {
  .registration-form .chapter.login .form-article {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .registration-form .chapter > .page {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .chapter > .page {
    width: 100%;
    min-height: 100%;
    padding-top: 120px;
    padding-bottom: 60px;
    overflow: hidden;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1199px) {
  .registration-form .chapter > .page {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.registration-form .reset-password .form-article {
  width: auto;
  padding-bottom: 50px;
}
@media only screen and (min-width: 480px) {
  .registration-form .reset-password .form-article {
    width: 290px;
  }
}
@media only screen and (min-width: 1024px) {
  .registration-form .reset-password .form-article {
    padding-top: 10px;
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 768px) {
  .registration-form .reset-password .form-article fieldset {
    padding-top: 50px;
  }
}
.registration-form .reset-password .form-row:last-child {
  *zoom: 1;
  text-align: center;
}
.registration-form .reset-password .form-row:last-child:before {
  content: " ";
  display: table;
}
.registration-form .reset-password .form-row:last-child:after {
  content: " ";
  display: table;
  clear: both;
}
.registration-form .reset-password .btn-back {
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: none;
  margin-top: 20px;
  margin: 0 auto;
  font-weight: 500;
  display: inline-block;
}
.registration-form .reset-password .btn-reset-password {
  width: 100%;
  margin: 10px 0 20px 0;
}
@media only screen and (min-width: 768px) {
  .registration-form .reset-password .btn-reset-password {
    margin: 10px 0 40px 0;
  }
}
.registration-form .register .btn-submit-registration {
  margin-top: 10px;
}
.registration-form .register .form-row + .form-row {
  padding-top: 20px;
}
.registration-form .btn-submit-registration {
  margin-top: 20px;
  width: 100%;
}
.registration-form .form-article {
  *zoom: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 290px;
  margin: 0 auto;
}
.registration-form .form-article:before {
  content: " ";
  display: table;
}
.registration-form .form-article:after {
  content: " ";
  display: table;
  clear: both;
}
.registration-form .form-article h2 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 29px;
  font-weight: 500;
  padding: 10px 0;
  text-align: center;
}
.registration-form .form-article .buttons {
  text-align: center;
  padding: 20px 0;
  margin: 0;
}
.registration-form .form-article p,
.registration-form .form-article span {
  text-align: center;
  display: block;
}
.registration-form .form-article fieldset {
  padding-top: 10px;
}
.registration-form .form-article .no-account {
  padding-top: 0;
}
.registration-form .form-article span:not(.error-msg) {
  color: #707580;
  display: block;
}
.registration-form .form-article span.entity-badge {
  display: none;
}
.registration-form .form-article a:not(.btn-text) {
  color: #000000;
  font-weight: 500;
}
.registration-form .form-article small {
  display: block;
  padding-top: 10px;
}
.registration-form .form-article .custom-checkbox + label {
  font-size: 14px;
  font-size: 1.4rem;
}
.registration-form .form-article .btn-circle {
  margin: 0 5px;
}
.registration-form .form-article .terms {
  padding-top: 10px;
}
.registration-form .error-msg {
  text-align: left;
}
.error-msg {
  color: #ff1e43;
}
.error .error-msg {
  display: block;
}
.error input[type="text"],
.error input[type="number"],
.error input[type="email"],
.error input[type="password"] {
  border-color: #ff1e43;
}
.error input[type="text"] + .error-msg,
.error input[type="number"] + .error-msg,
.error input[type="email"] + .error-msg,
.error input[type="password"] + .error-msg {
  color: #ff1e43;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.error .custom-input input[type="text"] + .error-msg,
.error .custom-input input[type="number"] + .error-msg,
.error .custom-input input[type="email"] + .error-msg,
.error .custom-input input[type="password"] + .error-msg {
  color: #ff1e43;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.custom-input {
  position: relative;
}
.custom-input input[type="text"],
.custom-input input[type="number"],
.custom-input input[type="email"],
.custom-input input[type="password"],
.custom-input input[type="tel"] {
  background-color: transparent;
  padding-top: 10px;
}
.custom-input input[type="text"].optional,
.custom-input input[type="number"].optional,
.custom-input input[type="email"].optional,
.custom-input input[type="password"].optional,
.custom-input input[type="tel"].optional {
  font-style: italic;
}
.custom-input input[type="text"].optional + label,
.custom-input input[type="number"].optional + label,
.custom-input input[type="email"].optional + label,
.custom-input input[type="password"].optional + label,
.custom-input input[type="tel"].optional + label {
  font-style: italic;
}
.custom-input input[type="text"]:not(:placeholder-shown) + label,
.custom-input input[type="number"]:not(:placeholder-shown) + label,
.custom-input input[type="email"]:not(:placeholder-shown) + label,
.custom-input input[type="password"]:not(:placeholder-shown) + label,
.custom-input input[type="tel"]:not(:placeholder-shown) + label,
.custom-input input[type="text"]:focus + label,
.custom-input input[type="number"]:focus + label,
.custom-input input[type="email"]:focus + label,
.custom-input input[type="password"]:focus + label,
.custom-input input[type="tel"]:focus + label {
  font-size: 10px;
  font-size: 1rem;
  top: 1px;
  cursor: auto;
}
.custom-input input[type="text"]::-webkit-input-placeholder,
.custom-input input[type="number"]::-webkit-input-placeholder,
.custom-input input[type="email"]::-webkit-input-placeholder,
.custom-input input[type="password"]::-webkit-input-placeholder,
.custom-input input[type="tel"]::-webkit-input-placeholder {
  opacity: 0;
}
.custom-input input[type="text"]:-ms-input-placeholder,
.custom-input input[type="number"]:-ms-input-placeholder,
.custom-input input[type="email"]:-ms-input-placeholder,
.custom-input input[type="password"]:-ms-input-placeholder,
.custom-input input[type="tel"]:-ms-input-placeholder {
  opacity: 0;
}
.custom-input input[type="text"]::-ms-input-placeholder,
.custom-input input[type="number"]::-ms-input-placeholder,
.custom-input input[type="email"]::-ms-input-placeholder,
.custom-input input[type="password"]::-ms-input-placeholder,
.custom-input input[type="tel"]::-ms-input-placeholder {
  opacity: 0;
}
.custom-input input[type="text"]::placeholder,
.custom-input input[type="number"]::placeholder,
.custom-input input[type="email"]::placeholder,
.custom-input input[type="password"]::placeholder,
.custom-input input[type="tel"]::placeholder {
  opacity: 0;
}
.custom-input input[type="text"]:disabled + label,
.custom-input input[type="number"]:disabled + label,
.custom-input input[type="email"]:disabled + label,
.custom-input input[type="password"]:disabled + label,
.custom-input input[type="tel"]:disabled + label {
  color: #e5e5e5;
}
.custom-input input[type="text"]:-moz-read-only,
.custom-input input[type="number"]:-moz-read-only,
.custom-input input[type="email"]:-moz-read-only,
.custom-input input[type="password"]:-moz-read-only,
.custom-input input[type="tel"]:-moz-read-only {
  padding-right: 60px;
}
.custom-input input[type="text"]:read-only,
.custom-input input[type="number"]:read-only,
.custom-input input[type="email"]:read-only,
.custom-input input[type="password"]:read-only,
.custom-input input[type="tel"]:read-only {
  padding-right: 60px;
}
.custom-input input[type="text"]:-moz-read-only:not(.mbsc-comp),
.custom-input input[type="number"]:-moz-read-only:not(.mbsc-comp),
.custom-input input[type="email"]:-moz-read-only:not(.mbsc-comp),
.custom-input input[type="password"]:-moz-read-only:not(.mbsc-comp),
.custom-input input[type="tel"]:-moz-read-only:not(.mbsc-comp) {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.custom-input input[type="text"]:read-only:not(.mbsc-comp),
.custom-input input[type="number"]:read-only:not(.mbsc-comp),
.custom-input input[type="email"]:read-only:not(.mbsc-comp),
.custom-input input[type="password"]:read-only:not(.mbsc-comp),
.custom-input input[type="tel"]:read-only:not(.mbsc-comp) {
  color: #e5e5e5;
  border-color: #e5e5e5;
}
.custom-input label {
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 18px;
  position: absolute;
  text-transform: none;
  letter-spacing: 0.5px;
  top: 19px;
  cursor: text;
  color: #707580;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-input .unit {
  font-size: 14px;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  top: 17px;
  font-weight: 500;
}
.custom-input .status-msg {
  font-size: 10px;
  font-size: 1rem;
  letter-spacing: 1px;
  position: absolute;
  right: 0;
  top: 16px;
  text-transform: uppercase;
}
.custom-input + .error-msg {
  position: absolute;
}
.page-rules article h1 {
  padding-top: 0;
  margin-top: 0;
}
.popup {
  background: #000000;
  z-index: 10000;
  display: none;
}
.popup.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 80vh;
  max-height: 80vh;
}
.popup.show {
  display: block;
}
.popup .btn-close-popup {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
}
.popup img {
  max-width: 80vh;
  max-height: 80vh;
}
.filters-table td {
  padding: 0 4px;
  vertical-align: bottom;
}
.filters-table td:first-child {
  padding-left: 0;
}
.filters-table td.last-child {
  padding-right: 0px;
}
.filters-table .label {
  color: #000000;
  display: block;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 9px;
}
.react-datetime-picker__calendar--open {
  z-index: 20;
}
.react-daterange-picker,
.react-datetime-picker {
  font-size: 12px;
}
.react-daterange-picker .react-daterange-picker__wrapper,
.react-datetime-picker .react-daterange-picker__wrapper,
.react-daterange-picker .react-datetime-picker__wrapper,
.react-datetime-picker .react-datetime-picker__wrapper {
  height: 24px;
  border-color: #ccc;
}
.react-daterange-picker .react-daterange-picker__inputGroup input,
.react-datetime-picker .react-daterange-picker__inputGroup input,
.react-daterange-picker .react-datetime-picker__inputGroup input,
.react-datetime-picker .react-datetime-picker__inputGroup input {
  height: 24px;
}
.react-daterange-picker .react-daterange-picker__clear-button,
.react-datetime-picker .react-daterange-picker__clear-button {
  padding: 0;
}
.react-daterange-picker .react-daterange-picker__clear-button svg,
.react-datetime-picker .react-daterange-picker__clear-button svg {
  width: 16px;
  height: 16px;
}
.inline-button-wrapper {
  display: inline-block;
  vertical-align: middle;
}
.inline-button-wrapper .btn {
  height: auto !important;
  padding: 6px 12px !important;
  line-height: inherit !important;
  margin-top: 0;
  margin-bottom: 0;
}
.inline-button-wrapper .form-control {
  height: 34px;
  width: 180px;
}
.inline-button-wrapper .form-control .react-daterange-picker .react-daterange-picker__wrapper {
  border: 0;
}
.custom-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.compare-table {
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
}
.compare-table tr:nth-child(odd) td {
  background-color: #eee;
}
.compare-table td {
  padding: 6px;
  vertical-align: top;
  border: 1px solid #ccc;
}
.compare-table td.green {
  background-color: #bafdab !important;
}
.compare-table td.red {
  background-color: #fdabab !important;
}
.compare-table .video-section + .video-section {
  padding-top: 10px;
  border-top: 1px solid #eee;
  margin-top: 10px;
}
.margin-0 {
  margin: 0 !important;
}
.break-word {
  word-break: break-word;
}
.table-font-12 {
  font-size: 12px;
}
.table-font-10 {
  font-size: 10px;
}
.table-header-white thead th,
.table-header-white thead td {
  background-color: #fff;
}
.table-striped td {
  background-color: #fff;
}
.landing-section {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: 'suisseInt', sans-serif;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (min-width: 768px) {
  .landing-section {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
  }
}
.landing-section.white {
  background-color: #ffffff;
}
.landing-section.loading {
  min-height: 200px;
}
@media only screen and (min-width: 768px) {
  .landing-section.loading {
    min-height: 400px;
  }
}
.landing-section .page {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1308px;
}
.landing-section .section-article {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.landing-section .landing-section-title {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: bold;
}
@media only screen and (min-width: 768px) {
  .landing-section .landing-section-title {
    font-size: 48px;
    font-size: 4.8pxrem;
    line-height: 56px;
  }
}
.landing-section .landing-section-title.alt {
  font-weight: 500;
  text-align: center;
  width: 100%;
}
.landing-section .landing-section-title .dynamic-row {
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .landing-section .landing-section-title .dynamic-row {
    height: 56px;
  }
}
.landing-section .landing-section-subtitle {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: bold;
}
.landing-section .landing-section-subtitle .dynamic-row {
  height: 56px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.landing-section .landing-section-subtitle .dynamic-titles {
  -webkit-animation: dynamic-titles 8s 1s ease-out infinite;
  animation: dynamic-titles 8s 1s ease-out infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.landing-section .landing-section-subtitle .dynamic-title {
  height: 56px;
  overflow: hidden;
}
.landing-section .lead {
  margin: 0;
  padding: 0;
}
.landing-section .opacity-wrapper {
  -webkit-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.landing-section .opacity-wrapper.show {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.landing-section br.mobile-only {
  display: block;
}
@media only screen and (min-width: 768px) {
  .landing-section br.mobile-only {
    display: none;
  }
}
.landing-section br.tablet-forward {
  display: none;
}
@media only screen and (min-width: 768px) {
  .landing-section br.tablet-forward {
    display: block;
  }
}
.section-blog-author {
  background-color: #e8eced;
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .section-blog-author {
    padding: 56px 0;
  }
}
@media only screen and (max-width: 768px) {
  .section-blog-author > .page {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .section-blog-author > .page {
    max-width: 924px;
  }
}
.section-blog-author .card-author {
  margin: 0;
}
.section-blog-author .card-author h4 {
  color: #000000;
}
@media only screen and (min-width: 768px) {
  .section-blog-author .card-author .description {
    margin-top: 16px;
  }
}
.section-blog-author .card-author .user-txt {
  color: #424547;
}
@media only screen and (min-width: 768px) {
  .section-blog-author .card-author .user-txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}
.section-blog-detail {
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .section-blog-detail > .page {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .section-blog-detail > .page {
    max-width: 824px;
  }
}
@media only screen and (min-width: 1199px) {
  .section-blog-detail > .page {
    max-width: 924px;
  }
}
.section-blog-detail .actions {
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (min-width: 768px) {
  .section-blog-detail .actions {
    height: 64px;
  }
}
.section-blog-detail .actions .button {
  margin-left: -6px;
}
.section-jobs {
  padding: 16px 0 80px 0;
}
@media only screen and (min-width: 768px) {
  .section-jobs {
    padding-bottom: 120px;
  }
}
.section-jobs > .page {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 1094px;
}
@media only screen and (max-width: 768px) {
  .section-jobs > .page {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.section-jobs .resources-article-detail {
  color: #424547;
}
.section-jobs .resources-article-detail h2,
.section-jobs .resources-article-detail h3 {
  font-weight: bold;
  color: #000000;
}
.section-jobs .job-success {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 32px;
  text-align: center;
  color: #424547;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-top: 134px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-success {
    text-align: left;
    height: auto;
    padding-top: 104px;
  }
}
.section-jobs .job-success h1 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 32px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 0.5px;
  padding-bottom: 16px;
  color: #000000;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-success h1 {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 48px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.section-jobs .job-success h1 img {
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-success h1 img {
    margin-bottom: 0;
    margin-right: 16px;
  }
}
.section-jobs .job-success p {
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-success p {
    line-height: 40px;
  }
}
.section-jobs .job-success .actions {
  margin-top: 40px;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-success .actions {
    margin-top: 56px;
    text-align: left;
  }
}
.section-jobs .job-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.section-jobs .job-details {
  padding: 16px 0 48px 0;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-details {
    padding-bottom: 72px;
  }
}
.section-jobs .job-details h1 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 48px;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-details h1 {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 64px;
    padding-right: 140px;
  }
}
.section-jobs .job-details .job-tags {
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 8px;
  color: #707580;
}
.section-jobs .job-details .btn-blog-copy-link {
  margin-top: 24px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .job-details .btn-blog-copy-link {
    position: absolute;
    right: 0;
    top: 8px;
  }
}
.section-jobs .apply-for-job {
  margin: 72px 0;
  width: 100%;
  max-width: 456px;
}
.section-jobs .apply-for-job h2 {
  padding-bottom: 24px;
}
.section-jobs .apply-for-job .pro-custom-input input[type="text"],
.section-jobs .apply-for-job .pro-custom-input input[type="email"] {
  background-color: #ffffff;
}
.section-jobs .apply-for-job .pro-custom-input label {
  pointer-events: none;
  cursor: default;
  z-index: 1;
}
.section-jobs .apply-for-job .pro-textarea {
  font-size: 16px;
  font-size: 1.6rem;
}
.section-jobs .apply-for-job .pro-textarea::-webkit-input-placeholder {
  font-size: 16px;
  font-size: 1.6rem;
  color: #707580;
}
.section-jobs .apply-for-job .pro-textarea:-ms-input-placeholder {
  font-size: 16px;
  font-size: 1.6rem;
  color: #707580;
}
.section-jobs .apply-for-job .pro-textarea::-ms-input-placeholder {
  font-size: 16px;
  font-size: 1.6rem;
  color: #707580;
}
.section-jobs .apply-for-job .pro-textarea::placeholder {
  font-size: 16px;
  font-size: 1.6rem;
  color: #707580;
}
.section-jobs .apply-for-job .textarea-counter {
  font-size: 10px;
  font-size: 1rem;
  line-height: 1;
  color: #707580;
}
.section-jobs .apply-for-job .form-row-submit {
  text-align: center;
  margin-top: 56px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .apply-for-job .form-row-submit {
    text-align: left;
  }
}
.section-jobs .apply-for-job .upload-file {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media only screen and (min-width: 768px) {
  .section-jobs .apply-for-job .upload-file {
    margin-top: 32px;
  }
}
.section-jobs .apply-for-job .upload-file-description {
  color: #707580;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 24px;
}
.section-jobs .apply-for-job .upload-file-description small {
  font-size: 10px;
  font-size: 1rem;
  line-height: 16px;
  display: block;
}
.section-jobs .apply-for-job .upload-file-actions {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: 500;
  color: #4e63ce;
  white-space: nowrap;
}
.section-jobs .apply-for-job .upload-file-actions:hover,
.section-jobs .apply-for-job .upload-file-actions:active {
  color: #6889e2;
}
.section-jobs .apply-for-job .upload-file-stack {
  padding-top: 12px;
}
.section-jobs .apply-for-job .upload-file-stack .file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
  color: #000000;
}
.section-jobs .apply-for-job .upload-file-stack .file h4 {
  word-break: normal;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 24px;
  font-weight: normal;
}
.section-jobs .apply-for-job .upload-file-stack .file .btn-editor {
  white-space: nowrap;
  margin-left: 16px;
}
.section-jobs .language-switcher {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #4e63ce;
  -ms-flex-item-align: end;
      align-self: flex-end;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.section-jobs .language-switcher .lang {
  color: #4e63ce;
  cursor: pointer;
}
.section-jobs .language-switcher .lang.active {
  font-weight: bold;
}
.section-jobs .language-switcher .lang:hover,
.section-jobs .language-switcher .lang:active {
  color: #6889e2;
}
.section-jobs .lead {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 40px;
  color: #424547;
  padding-bottom: 56px;
  padding-top: 8px;
}
@media only screen and (min-width: 768px) {
  .section-jobs .lead {
    padding-top: 16px;
  }
}
.section-jobs .landing-section-title {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 32px;
  padding-bottom: 24px;
  font-weight: bold;
  text-align: left;
}
@media only screen and (min-width: 768px) {
  .section-jobs .landing-section-title {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 48px;
    padding-bottom: 32px;
  }
}
.section-jobs .list-jobs {
  width: 100%;
}
.section-jobs .list-jobs .item {
  padding-top: 24px;
}
.section-jobs .list-jobs .item:first-child {
  padding-top: 0;
}
.blog-article-header {
  -webkit-transition: transform 300ms ease-out;
  -webkit-transition: -webkit-transform 300ms ease-out;
  transition: -webkit-transform 300ms ease-out;
  transition: transform 300ms ease-out;
  transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
  -webkit-transform: translate(0, -100px);
  transform: translate(0, -100px);
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .blog-article-header {
    height: 0px;
    min-height: 0;
  }
  .blog-article-header .page {
    display: none;
  }
}
.blog-article-header.show {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.blog-article-header .blog-article-info {
  position: relative;
}
@media only screen and (min-width: 768px) {
  .blog-article-header .blog-article-info {
    max-width: 744px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 24px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media only screen and (min-width: 880px) {
  .blog-article-header .blog-article-info {
    margin: 0 auto;
    left: -24px;
    padding-left: 0;
  }
}
.blog-article-header .blog-article-info-title {
  word-break: normal;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 24px;
}
.blog-article-header .blog-progress-bar {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 12px;
}
@media only screen and (min-width: 768px) {
  .blog-article-header .blog-progress-bar {
    height: 8px;
  }
}
.blog-article-header .blog-progress-bar .progress-bar {
  -webkit-box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #000000;
}
.resources-article-detail {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 40px;
  text-align: left;
  width: 100%;
  color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  letter-spacing: 0.2px;
}
.resources-article-detail h1 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.5px;
  padding-top: 48px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail h1 {
    font-size: 48px;
    font-size: 4.8rem;
    line-height: 64px;
    padding-top: 64px;
  }
}
.resources-article-detail h2 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 48px;
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-top: 72px;
}
.resources-article-detail h2 + p {
  padding-top: 16px;
}
.resources-article-detail h3 {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 32px;
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.5px;
  padding-top: 40px;
}
.resources-article-detail h3 + p {
  padding-top: 8px;
}
.resources-article-detail h4 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 28px;
  color: #000000;
  padding-bottom: 20px;
  font-weight: 500;
}
.resources-article-detail ul,
.resources-article-detail ol {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 0.2px;
  line-height: 34px;
  margin: 0;
  padding: 16px 0 0 20px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail ul,
  .resources-article-detail ol {
    padding-left: 30px;
  }
}
.resources-article-detail ul li,
.resources-article-detail ol li {
  padding: 0 0 14px 0;
  margin-left: 10px;
}
.resources-article-detail ul li:last-child,
.resources-article-detail ol li:last-child {
  padding-bottom: 0;
}
.resources-article-detail ul li {
  list-style: disc;
}
.resources-article-detail sup,
.resources-article-detail sub {
  font-size: 10px;
  font-size: 1rem;
}
.resources-article-detail sub {
  vertical-align: 0em;
}
.resources-article-detail sup {
  vertical-align: 1em;
  top: 0;
}
.resources-article-detail p {
  padding: 0;
  margin: 0;
}
.resources-article-detail p + p {
  padding-top: 10px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail p + p {
    padding-top: 14px;
  }
}
.resources-article-detail a {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  font-weight: bold;
  color: inherit;
}
.resources-article-detail strong {
  font-weight: 500;
}
.resources-article-detail .lead {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 36px;
  letter-spacing: 0.5px;
  padding-top: 16px;
  color: #424547;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .lead {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 40px;
    padding-top: 24px;
  }
}
.resources-article-detail .cover-and-author-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.resources-article-detail .cover-and-author-wrapper + p {
  padding-top: 0;
}
.resources-article-detail .cover-and-author-wrapper a {
  text-decoration: none;
}
.resources-article-detail .cover {
  margin: 24px 0 0 0;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .cover {
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
    margin: 0 0 56px 0;
  }
}
.resources-article-detail .cover figcaption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 16px;
  left: 24px;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .cover figcaption {
    top: 24px;
  }
}
.resources-article-detail .card-author {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 24px 0 16px 0;
  line-height: 24px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .card-author {
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
    margin: 16px 0 32px 0;
  }
}
.resources-article-detail .card-author a {
  color: #424547;
}
.resources-article-detail .card-author h4 {
  padding: 0;
}
.resources-article-detail .blog-table-of-contents {
  padding-top: 72px;
}
.resources-article-detail .blog-table-of-contents.open .btn-toc-toggle em {
  display: inline-block;
}
.resources-article-detail .blog-table-of-contents.open .btn-toc-toggle em + em {
  display: none;
}
.resources-article-detail .blog-table-of-contents.open ul {
  display: block;
}
.resources-article-detail .blog-table-of-contents .btn-toc-toggle {
  font-size: 21px;
  font-size: 2.1rem;
  color: #424547;
  font-weight: normal;
}
.resources-article-detail .blog-table-of-contents .btn-toc-toggle span {
  color: #4e63ce;
}
.resources-article-detail .blog-table-of-contents .btn-toc-toggle span:hover {
  color: #6889e2;
}
.resources-article-detail .blog-table-of-contents .btn-toc-toggle em {
  font-style: normal;
  display: none;
}
.resources-article-detail .blog-table-of-contents .btn-toc-toggle em + em {
  display: inline-block;
}
.resources-article-detail .blog-table-of-contents a {
  font-weight: normal;
  color: #4e63ce;
  text-decoration: none;
}
.resources-article-detail .blog-table-of-contents a:hover {
  color: #6889e2;
}
.resources-article-detail .blog-table-of-contents ul {
  display: none;
}
.resources-article-detail .blog-embed-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-embed-media {
    margin-top: 56px;
  }
}
.resources-article-detail .blog-embed-media + p {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-embed-media + p {
    padding-top: 52px;
  }
}
.resources-article-detail .blog-embed-media.tiktok {
  display: block;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-embed-media.tiktok {
    min-width: 325px;
  }
}
.resources-article-detail .blog-media {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-media {
    margin-top: 56px;
  }
}
.resources-article-detail .blog-media + p {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-media + p {
    padding-top: 52px;
  }
}
.resources-article-detail .blog-media .media-container {
  position: relative;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .resources-article-detail .blog-media .btn-play-video {
    width: 56px;
    height: 56px;
  }
}
.resources-article-detail .blog-media img,
.resources-article-detail .blog-media video {
  border-radius: 4px;
  width: 100%;
}
.resources-article-detail .blog-media figcaption {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 24px;
  color: #424547;
  padding: 16px 0 0 0;
  letter-spacing: 0.5px;
}
.resources-article-detail .blog-media figcaption p {
  margin: 0;
  padding: 0;
}
.resources-article-detail .blog-media figcaption p + p {
  padding-top: 12px;
}
.resources-article-detail .blog-blockquote {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 44px;
  font-style: italic;
  color: #707580;
  padding: 0 0 0 18px;
  margin: 40px 0 0 0;
  position: relative;
  letter-spacing: 0.3px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-blockquote {
    margin-top: 56px;
    padding-left: 26px;
  }
}
.resources-article-detail .blog-blockquote:before {
  content: "";
  position: absolute;
  width: 2px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #8A919E;
}
.resources-article-detail .blog-blockquote p {
  padding: 0;
  margin: 0;
}
.resources-article-detail .blog-blockquote p + p {
  padding-top: 12px;
}
.resources-article-detail .blog-key-takeaways {
  background-color: #e8eced;
  margin: 72px -24px 0 -24px;
  padding: 32px 24px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-key-takeaways {
    margin-left: -40px;
    margin-right: -40px;
    padding: 32px 40px 40px 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .resources-article-detail .blog-key-takeaways {
    border-radius: 4px;
    margin-left: -64px;
    margin-right: -64px;
    padding-left: 90px;
    padding-right: 90px;
  }
}
.resources-article-detail .blog-key-takeaways h2,
.resources-article-detail .blog-key-takeaways h2.blog-key-takeaways-title {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0 0 8px 0;
  letter-spacing: 0.5px;
}
.resources-article-detail .blog-key-takeaways ul {
  padding-top: 8px;
  line-height: 40px;
  letter-spacing: 0.2px;
}
.resources-article-detail .blog-tag {
  border-radius: 12px;
  font-size: 12px;
  font-size: 1.2rem;
  background-color: #D5DDE0;
  color: #000000;
  text-transform: uppercase;
  height: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
  letter-spacing: 0.5px;
}
.resources-article-detail .blog-signup {
  color: #ffffff;
  margin: 40px -20px 0 -20px;
  padding: 30px 20px;
  text-align: center;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-image: url('https://viberate.ams3.cdn.digitaloceanspaces.com/landing/banner-gradient-blog-480.png');
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 56px -40px 0 -40px;
    padding: 32px 40px 40px 40px;
    background-image: url('https://viberate.ams3.cdn.digitaloceanspaces.com/landing/banner-gradient-blog-1024.png');
  }
}
@media only screen and (min-width: 800px) {
  .resources-article-detail .blog-signup {
    margin-left: calc((100vw - 744px) / -2);
    margin-right: calc((100vw - 744px) / -2);
    padding-left: 90px;
    padding-right: 90px;
  }
}
@media only screen and (min-width: 1024px) {
  .resources-article-detail .blog-signup {
    background-image: url('https://viberate.ams3.cdn.digitaloceanspaces.com/landing/banner-gradient-blog-1440.png');
  }
}
@media only screen and (min-width: 1439px) {
  .resources-article-detail .blog-signup {
    background-image: url('https://viberate.ams3.cdn.digitaloceanspaces.com/landing/banner-gradient-blog-1920.png');
  }
}
.resources-article-detail .blog-signup + p {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup + p {
    padding-top: 52px;
  }
}
.resources-article-detail .blog-signup .blog-signup-title {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 40px;
  color: inherit;
  font-weight: bold;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .blog-signup-title {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 32px;
  }
}
.resources-article-detail .blog-signup .blog-signup-description {
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 32px;
  margin-top: 32px;
  margin: 0;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .blog-signup-description {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 4px;
    line-height: 24px;
  }
}
.resources-article-detail .blog-signup .blog-signup-description p {
  margin: 0;
  padding: 0;
}
.resources-article-detail .blog-signup .blog-signup-description p + p {
  padding-top: 14px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .blog-signup-description p + p {
    padding-top: 4px;
  }
}
.resources-article-detail .blog-signup .blog-signup-form {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .blog-signup-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.resources-article-detail .blog-signup .form-row {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  .resources-article-detail .blog-signup .form-row {
    max-width: 360px;
    margin: 0 auto;
  }
}
.resources-article-detail .blog-signup .blog-signup-form-input {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  font-size: 1.6rem;
  background-color: #ffffff;
  height: 48px;
  padding: 0 16px;
  color: #707580;
  border: 0;
  max-width: 360px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .blog-signup-form-input {
    width: 360px;
  }
}
.resources-article-detail .blog-signup .blog-signup-form-input + .error-msg {
  bottom: -18px;
  left: 16px;
}
.resources-article-detail .blog-signup .btn-blog-signup-form-submit {
  margin-top: 32px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-signup .btn-blog-signup-form-submit {
    margin: 0 0 0 32px;
  }
}
.resources-article-detail .blog-black-promo {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 32px;
  background-color: #000000;
  color: #ffffff;
  text-align: center;
  margin: 40px -16px 0 -16px;
  max-width: 390px;
  padding: 40px 24px 48px 24px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-black-promo {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 24px;
    width: 100%;
    max-width: none;
    padding: 24px;
  }
}
@media only screen and (min-width: 880px) {
  .resources-article-detail .blog-black-promo {
    width: auto;
    margin-left: -40px;
    margin-right: -40px;
  }
}
.resources-article-detail .blog-black-promo + p {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-black-promo + p {
    padding-top: 52px;
  }
}
.resources-article-detail .blog-black-promo.shine {
  position: relative;
  overflow: hidden;
}
.resources-article-detail .blog-black-promo.shine:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
  -webkit-opacity: 0.2;
  -moz-opacity: 0.2;
  opacity: 0.2;
  -webkit-animation: shine 2s ease-in-out forwards;
  animation: shine 2s ease-in-out forwards;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1deg);
  content: "";
  position: absolute;
  top: -200px;
  left: -40%;
  width: 50px;
  bottom: -200px;
  background: #ffffff;
}
.resources-article-detail .blog-black-promo a {
  color: inherit;
  text-decoration: none;
}
.resources-article-detail .blog-black-promo .blog-black-promo-title {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 40px;
  font-weight: bold;
  padding-bottom: 32px;
  color: #ffffff;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-black-promo .blog-black-promo-title {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 32px;
    padding-bottom: 4px;
  }
}
.resources-article-detail .blog-black-promo .blog-black-promo-title br {
  display: none;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-black-promo .blog-black-promo-title br {
    display: block;
  }
}
.resources-article-detail .blog-black-promo p {
  margin: 0;
  padding: 0;
}
.resources-article-detail .blog-black-promo .blog-black-promo-actions {
  margin-top: 40px;
}
@media only screen and (min-width: 768px) {
  .resources-article-detail .blog-black-promo .blog-black-promo-actions {
    margin-top: 24px;
  }
}
